import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { variables } from "../../styles/variables";
import { BiggerLogos, SmallerLogos, StyledFooter } from "./Footer.style";

interface Props {}

const LOGO_EDURATE = `${process.env.PUBLIC_URL}/assets/logo-eduarte.png`;
const LOGO_BELFRY = `${process.env.PUBLIC_URL}/assets/logo-belfry.jpg`;
const LOGO_RADLINIOKI = `${process.env.PUBLIC_URL}/assets/logo-radlinioki.jpg`;
const LOGO_KOMITET = `${process.env.PUBLIC_URL}/assets/logo-komitet.png`;
const LOGO_NIW = `${process.env.PUBLIC_URL}/assets/logo-niw.png`;
const LOGO_NOWEFIO = `${process.env.PUBLIC_URL}/assets/logo-nowefio.png`;
const NIW_URL = "www.niw.gov.pl";
const { breakpoints } = variables;

export const Footer = (props: Props) => {
  const { width } = useWindowDimensions();

  return (
    <a
      href={`https://${NIW_URL}`}
      style={{ textDecoration: "none" }}
      target="_blank"
      rel="noreferrer"
    >
      <StyledFooter>
        <SmallerLogos>
          <img src={LOGO_EDURATE} alt="Logo Eduarte" />
          <img src={LOGO_BELFRY} alt="Logo Belfry bazgrolą" />
          <img src={LOGO_RADLINIOKI} alt="Logo Radlinioki w biegu" />
        </SmallerLogos>
        <BiggerLogos>
          <img
            src={LOGO_KOMITET}
            alt="Logo Komitet do spraw pożytku publicznego"
          />
          <img src={LOGO_NIW} alt="Logo NIW" />
          {width > parseInt(breakpoints.sm) ? (
            <span>
              Sfinansowano ze środków Narodowego Instytutu Wolności - Centrum
              Rozwoju Społeczeństwa Obywatelskiego w ramach Rządowego Programu
              Fundusz Inicjatyw Obywatelskich NOWE FIO na lata 2021-2030
            </span>
          ) : null}
          <img src={LOGO_NOWEFIO} alt="Logo NOWE FIO" />
        </BiggerLogos>
        {width <= parseInt(breakpoints.sm) ? (
          <span>
            Sfinansowano ze środków Narodowego Instytutu Wolności - Centrum
            Rozwoju Społeczeństwa Obywatelskiego w ramach Rządowego Programu
            Fundusz Inicjatyw Obywatelskich NOWE FIO na lata 2021-2030
          </span>
        ) : null}
      </StyledFooter>
    </a>
  );
};
