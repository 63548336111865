import {
  Content,
  DataRow,
  Header,
  ImageWrapper,
  InfoWrapper,
  NewsImage,
  StyledPortableText,
  StyledSingleAnnouncement,
} from "./SingleAnnouncement.style";
import forUrl from "../../../../api/sanity/imageBuilder";
import { Announcement } from "../AnnouncementsList.types";
import { BlockContent } from "../../../../api/sanity/schema";
import { Button } from "../../../../components/button/Button";
import { useHistory, useRouteMatch } from "react-router-dom";

interface Props {
  announcement: Announcement;
}

export const SingleAnnouncement = (props: Props) => {
  const { mainImage, title, publishedAt, body, _id } = props.announcement;

  const history = useHistory();
  const { path } = useRouteMatch();

  return (
    <StyledSingleAnnouncement>
      <Header>{title}</Header>
      <ImageWrapper>
        {mainImage?.asset ? (
          <NewsImage
            src={forUrl(mainImage) as unknown as string}
            alt="zdjecie-newsa"
          />
        ) : null}
      </ImageWrapper>
      <Content>
        <StyledPortableText
          content={body as BlockContent}
          dataset={process.env.REACT_APP_SANITY_DATASET}
          projectId={process.env.REACT_APP_SANITY_CLIENT_ID}
        />
      </Content>
      <DataRow>
        <InfoWrapper>
          {new Date(publishedAt as string).toLocaleDateString("en-US")}
        </InfoWrapper>
        <Button
          variant="outlined"
          onClick={() => history.push(`${path}/${_id}`)}
        >
          Czytaj dalej
        </Button>
      </DataRow>
    </StyledSingleAnnouncement>
  );
};
