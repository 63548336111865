import styled from "styled-components";
import { variables } from "../../core/styles/variables";
const bannerImgUrl = `${process.env.PUBLIC_URL}/assets/banner.png`;
const { breakpoints } = variables;

export const StyledHomepage = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Banner = styled.div`
  height: 100%;
  width: 100%;
  background-image: url(${bannerImgUrl});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  @media (max-width: ${breakpoints.xlg}) {
    background-size: 70%;
  }
  @media (max-width: ${breakpoints.lg}) {
    background-size: 85%;
  }
  @media (max-width: ${breakpoints.md}) {
    background-size: 100%;
  }
  @media (max-width: ${breakpoints.sm}) {
    background-size: 140%;
  }
  @media (max-width: ${breakpoints.xs}) {
    background-size: 160%;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TitleImg = styled.img`
  margin-bottom: 12rem;
  transform: scale(0.8);
`;
