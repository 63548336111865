import React, { useContext, useEffect, useState } from "react";
import { TasksService } from "../../api/tasks/tasksService";
import { Task } from "../../api/tasks/types";
import AuthContext from "../../contexts/auth/AuthContext";
import UIContext from "../../contexts/ui/UIContext";
import { StyledTasksList } from "../adminpage/Tasks/TasksList.style";
import SingleUserTask from "./SingleUserTask";
import { TasksHeader } from "./UserTasks.styled";

type Props = {};

const UserTasks = (props: Props) => {
  const {
    user: { id },
    loggedIn,
  } = useContext(AuthContext);
  const { showSnackbar, showLoading } = useContext(UIContext);
  const [tasks, setTasks] = useState<Task[]>([]);

  useEffect(() => {
    showLoading(true);
    TasksService.getTasksForUser(id)
      .then((response) => {
        setTasks(response.data.data);
        showLoading(false);
        showSnackbar("success", "Dane załadowano pomyślnie");
      })
      .catch((err) => {
        showLoading(false);
        showSnackbar("error", err.message);
      });
  }, [id, showLoading, showSnackbar, loggedIn]);

  return loggedIn ? (
    <StyledTasksList>
      <TasksHeader>
        <span>Tytuł</span>
        <span>Podana odpowiedź</span>
        <span>Data wysłania</span>
        <span>Co dalej?</span>
      </TasksHeader>
      {tasks.map((task) => (
        <SingleUserTask key={task._id} task={task} />
      ))}
    </StyledTasksList>
  ) : (
    <h1>Nie masz dostępu do tej strony, zaloguj się</h1>
  );
};

export default UserTasks;
