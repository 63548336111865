import styled from "styled-components";

export const StyledSingleTask = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 30% 20% 20% 30%;
  border-radius: 0.5rem;
  background-color: transparent;
  padding: 1rem;
  font-size: ${({ theme }) => theme.fontSize.sm};
  border: 1px solid black;
  box-shadow: 0.1rem 0.3rem 0.6rem grey;
`;

export const ActionsWrapper = styled.div`
  display: flex;
`;
