import { RegisterOptions, UseFormRegister } from "react-hook-form";
import { IconType } from "react-icons";
import { FaCheck } from "react-icons/fa";
import {
  HiddenCheckbox,
  IconCheckbox,
  StyledCheckbox,
  StyledCheckboxContainer,
} from "./Checkbox.style";

interface Props {
  label: string;
  name: string;
  register: UseFormRegister<any>;
  options?: RegisterOptions;
  value: boolean | number;
  variant: "classic" | "icon";
  icons?: { true: IconType; false: IconType };
}

export const Checkbox = (props: Props) => {
  const { label, name, register, options, value, variant, icons } = props;

  const renderCheckbox = () => {
    switch (variant) {
      case "classic":
        return (
          <StyledCheckbox htmlFor={name}>
            {value ? <FaCheck /> : null}
          </StyledCheckbox>
        );
      case "icon":
        return (
          <IconCheckbox htmlFor={name}>
            {value ? icons?.true({}) : icons?.false({})}
          </IconCheckbox>
        );
    }
  };

  return (
    <StyledCheckboxContainer>
      <span>{label}:</span>
      {renderCheckbox()}
      <HiddenCheckbox
        type="checkbox"
        id={name}
        {...register(name, options ?? {})}
      />
    </StyledCheckboxContainer>
  );
};
