import React, { useContext, useEffect, useState } from "react";
import UIContext from "../../../contexts/ui/UIContext";
import {
  AnnouncementsWrapper,
  Header,
  NoAnnouncements,
  StyledAnnouncementsList,
} from "./AnnouncementsList.style";
import { AxiosError } from "axios";
import { SingleAnnouncement } from "./single-announcement/SingleAnnouncement";
import sanityClient from "../../../api/sanity/sanityClient";
import { Announcements } from "./AnnouncementsList.types";

interface Props {}

export const AnnouncementsList = (props: Props) => {
  const { showLoading, showSnackbar } = useContext(UIContext);
  const [announcements, setAnnouncements] = useState<Announcements>([]);
  useEffect(() => {
    showLoading(true);
    sanityClient
      .fetch(
        `*[_type == "news" && !(_id in path('drafts.**'))] | order(publishedAt desc)`
      )
      .then((response: Announcements) => {
        setAnnouncements(response);
        showLoading(false);
      })
      .catch((err: AxiosError) => {
        showLoading(false);
        showSnackbar("error", err.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledAnnouncementsList>
      <Header>Aktualności</Header>
      <AnnouncementsWrapper>
        {announcements.length !== 0 ? (
          announcements.map((announcement, index: number) => {
            return (
              <SingleAnnouncement key={index} announcement={announcement} />
            );
          })
        ) : (
          <NoAnnouncements>Brak aktualności</NoAnnouncements>
        )}
      </AnnouncementsWrapper>
    </StyledAnnouncementsList>
  );
};
