import styled from "styled-components";
import { variables } from "../../core/styles/variables";
const { breakpoints } = variables;

export const StyledContact = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${breakpoints.lg}) {
    font-size: 8px;
  }
  margin-bottom: 2rem;
`;
export const ContactHeader = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.head3};
  text-align: center;
`;
export const ContactContent = styled.div`
  margin: 0 10%;
  display: grid;
  grid-template-columns: repeat(3, 33%);
  font-size: ${({ theme }) => theme.fontSize.md};
  @media (max-width: ${breakpoints.lg}) {
    margin: 0 6%;
    font-size: ${({ theme }) => theme.fontSize.sm};
  }
  @media (max-width: ${breakpoints.md}) {
    margin: 0 3%;
  }
  @media (max-width: ${breakpoints.sm}) {
    grid-template-columns: none;
    grid-template-rows: 3;
    grid-row-gap: 6rem;
    font-size: ${({ theme }) => theme.fontSize.md};
  }
`;
export const ContactColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ContactImage = styled.img`
  width: 15rem;
  height: auto;
`;
export const ContactData = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;
export const DataHeader = styled.h3``;
export const EmailRow = styled.span`
  font-family: Arial, Helvetica, sans-serif;
  font-weight: ${({ theme }) => theme.fontWeight.fontBold};
`;
