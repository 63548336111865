import { SnackbarType } from "../../contexts/ui/types";
import { StyledSnackbar } from "./Snackbar.style";
import {
  FaInfoCircle,
  FaCheckCircle,
  FaExclamationCircle,
} from "react-icons/fa";

interface Props {
  type: SnackbarType;
  message: string;
}

export const Snackbar = (props: Props) => {
  const { type, message } = props;
  const renderIcon = (type: SnackbarType) => {
    switch (type) {
      case "error":
        return <FaExclamationCircle />;
      case "info":
        return <FaInfoCircle />;
      case "success":
        return <FaCheckCircle />;
    }
  };
  return (
    <StyledSnackbar type={type}>
      {renderIcon(type)}
      {message}
    </StyledSnackbar>
  );
};
