import React from "react";
import { FaMale, FaMapSigns } from "react-icons/fa";
import { ButtonText, StyledPlayButton } from "./PlayButton.style";

interface Props {
  onClick: () => void;
}

export const PlayButton = ({ onClick }: Props) => {
  return (
    <StyledPlayButton onClick={onClick}>
      <ButtonText>Wejdź do gry</ButtonText>
      <FaMapSigns />
      <FaMale />
    </StyledPlayButton>
  );
};
