import styled from "styled-components";
import { variables } from "../../core/styles/variables";
import { ButtonColor, ButtonVariant } from "../types";
const { breakpoints } = variables;

interface StyledButtonProps {
  disabled: boolean;
  color: ButtonColor;
  variant: ButtonVariant;
}

type IconButtonProps = Omit<StyledButtonProps, "variant">;

export const StyledButton = styled.button<StyledButtonProps>`
  font-family: "Veneer Three";
  outline: none;
  padding: 1.2rem 1.5rem;
  border-radius: 1rem;
  box-shadow: 0.1rem 0.1rem 0.6rem black;
  font-size: ${({ theme }) => theme.fontSize.lg};
  transition: all 0.2s;
  border: ${(props) =>
    props.variant === "contained"
      ? `none`
      : `1px solid ${props.theme.color.white}`};
  color: ${(props) =>
    props.variant === "contained"
      ? props.theme.color.white
      : props.theme.color.white};
  background-color: ${(props) =>
    !props.disabled
      ? props.variant === "contained"
        ? props.theme.color[props.color]
        : "transparent"
      : "lightgray"};
  cursor: ${(props) => (!props.disabled ? "none" : "pointer")};

  &:not(:last-child) {
    margin-right: 1rem;
  }

  &:hover {
    cursor: pointer;
    /* border-radius: 0.7rem; */
    box-shadow: 0.3rem 0.3rem 0.8rem black;
    transform: translateX(-1px) translateY(-1px);
  }
  @media (max-width: ${breakpoints.sm}) {
    padding: 1rem 1.2rem;
  }
`;

export const IconButton = styled.button<IconButtonProps>`
  display: flex;
  align-items: center;
  & > * {
    color: ${({ color, theme }) => theme.color[color]};
    transform: scale(1.3);
  }
  background-color: transparent;
  border: 1px solid grey;
  padding: 0.8rem;
  border-radius: 50%;
  &:hover {
    & > *:not(span) {
      transition: all 0.1s;
      transform: scale(1.5);
    }
    & > span {
      bottom: 3rem;
      opacity: 1;
    }
  }
  position: relative;
`;

export const Tooltip = styled.span`
  position: absolute;
  bottom: 2rem;
  opacity: 0;
  transition: all 0.2s;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.3rem 0.5rem;
  font-size: ${({ theme }) => theme.fontSize.xsm};
  font-weight: ${({ theme }) => theme.fontWeight.fontSemiBold};
  border-radius: 0.3rem;
  border: 1px solid ${({ theme }) => theme.color.primary};
  background-color: lightgray;
`;
