import { AxiosError } from "axios";
import { useContext, useEffect, useState } from "react";
import sanityClient from "../../api/sanity/sanityClient";
import UIContext from "../../contexts/ui/UIContext";
import {
  ContactColumn,
  ContactContent,
  ContactData,
  ContactHeader,
  ContactImage,
  EmailRow,
  StyledContact,
} from "./Contact.style";
import { ContactDataType, KeysType } from "./Contact.types";
const DIRTY_LOGO_RADLINIOKI = `${process.env.PUBLIC_URL}/assets/radlinioki-dirty.png`;
const DIRTY_LOGO_BELFRY = `${process.env.PUBLIC_URL}/assets/belfry-dirty.png`;
const DIRTY_LOGO_EDUARTE = `${process.env.PUBLIC_URL}/assets/eduarte-dirty.png`;

interface Props {}

export const Contact = (props: Props) => {
  const [contactData, setContactData] = useState<ContactDataType>();
  const { showLoading, showSnackbar } = useContext(UIContext);

  useEffect(() => {
    showLoading(true);
    sanityClient
      .fetch(`*[_type == "pages" && title == "contact"]`)
      .then((response) => {
        const data = response[0];
        const content = (({
          belfry_contact,
          eduarte_contact,
          radlinioki_contact,
        }) => ({ belfry_contact, eduarte_contact, radlinioki_contact }))(data);
        setContactData(content);
        showLoading(false);
      })
      .catch((err: AxiosError) => {
        showLoading(false);
        showSnackbar("error", err.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const returnDataByKey = (key: KeysType) => {
    switch (key) {
      case "belfry_contact":
        return {
          logoPath: DIRTY_LOGO_BELFRY,
          header: "Belfry Bazgrolą",
          content: contactData ? contactData[key] : null,
        };
      case "eduarte_contact":
        return {
          logoPath: DIRTY_LOGO_EDUARTE,
          header: "Fundacja Eduarte",
          content: contactData ? contactData[key] : null,
        };
      case "radlinioki_contact":
        return {
          logoPath: DIRTY_LOGO_RADLINIOKI,
          header: "Radlinioki w biegu",
          content: contactData ? contactData[key] : null,
        };
    }
  };

  return (
    <StyledContact>
      <ContactHeader>Kontakt</ContactHeader>
      <ContactContent>
        {Object.keys(contactData ? (contactData as ContactDataType) : {}).map(
          (key) => {
            const data = returnDataByKey(key as KeysType);
            return (
              <ContactColumn key={key}>
                <ContactImage src={data.logoPath} />
                <ContactData>
                  <ContactHeader>{data.header}</ContactHeader>
                  <span>{data.content?.address}</span>
                  <br />
                  <span>{data.content?.phone1}</span>
                  <span>{data.content?.phone2}</span>
                  <br />
                  <EmailRow>{data.content?.email}</EmailRow>
                  <br />
                  <span>{data.content?.facebook}</span>
                  <br />
                  <span>{data.content?.instagram}</span>
                </ContactData>
              </ContactColumn>
            );
          }
        )}
      </ContactContent>
    </StyledContact>
  );
};
