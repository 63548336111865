import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { MainNav, NavWrapper, StyledNavbar } from "./Navbar.style";
import { Button } from "../../../components/button/Button";
import AuthContext from "../../../contexts/auth/AuthContext";
import { UserType } from "../../../api/types";

interface Props {}

export const Navbar = (props: Props) => {
  const { loggedIn, handleLogout, user } = useContext(AuthContext);
  const history = useHistory();

  const renderAdminButton = () => {
    return user.type === UserType.ADMIN ? (
      <Button variant="outlined" onClick={() => history.push("/admin")}>
        Panel admina
      </Button>
    ) : null;
  };

  return (
    <StyledNavbar>
      <NavWrapper>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push("/")}
        >
          Strona główna
        </Button>
        <MainNav>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push("/oprojekcie")}
          >
            O projekcie
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push("/aktualnosci")}
          >
            Aktualności
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push("/blog")}
          >
            Blog
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push("/kontakt")}
          >
            Kontakt
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push("/onas")}
          >
            O nas
          </Button>
        </MainNav>
        {loggedIn ? (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push("/gra")}
              disabled={false}
            >
              Graj
            </Button>
            {renderAdminButton()}
            <Button variant="contained" color="primary" onClick={handleLogout}>
              Wyloguj
            </Button>
          </div>
        ) : (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push("/logowanie")}
            >
              Zaloguj sie
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push("/rejestracja")}
            >
              Zarejestruj sie
            </Button>
          </div>
        )}
      </NavWrapper>
    </StyledNavbar>
  );
};
