import { AxiosResponse } from "axios";
import Api from "..";
import { BasicResponseData, ExtendedResponseData } from "../auth/types";
import { Task, TaskRequest } from "./types";

const tasksUrl = '/tasks';

const addTask = (taskData: TaskRequest): Promise<AxiosResponse<BasicResponseData>> => {
  return Api.post(`${tasksUrl}/`, taskData);
}

const getTasksForUser = (userId: string): Promise<AxiosResponse<ExtendedResponseData<Task[]>>> => {
  return Api.get(`${tasksUrl}/${userId}`);
}

export const TasksService = {
  addTask,
  getTasksForUser
}