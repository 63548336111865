import { createGlobalStyle } from "styled-components";
import { variables } from "./variables";
const {
  secondaryRgb: { red: scndryRed, green: scndryGreen, blue: scndryBlue },
  primaryRgb: { red: prmryRed, green: prmryGreen, blue: prmryBlue },
  breakpoints
} = variables;
const BACKGROUND_URL = `${process.env.PUBLIC_URL}/assets/tlo.jpg`;
const VENEERFONT_URL = `${process.env.PUBLIC_URL}/fonts/VeneerThree.ttf`;
const UCHIYAMAFONT_URL = `${process.env.PUBLIC_URL}/fonts/uchiyama.ttf`;

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: "Veneer Three";
    font-style: normal;
    font-weight: 300;
    font-display: auto;
    src: url(${VENEERFONT_URL}) format("truetype");
  }
  @font-face {
    font-family: "Uchiyama";
    font-style: normal;
    font-weight: 300;
    font-display: auto;
    src: url(${UCHIYAMAFONT_URL}) format("truetype");
  }

  :root {
    font-size: 10px;
    @media (max-width: ${breakpoints.sm}) {
      font-size: 9px;
    }
  }
  body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    overflow-x: hidden;
    font-family: 'Uchiyama', 'Open sans', cursive, sans-serif;
    /* font-family: 'Cinzel Decorative', 'Open sans', cursive, sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    #root {
      min-height: 100vh; 
      height: 100vh;
      background-image: linear-gradient(
        to bottom,
        rgba(${`${prmryRed}, ${prmryGreen}, ${prmryBlue}`}, 0.6),
        rgba(${`${scndryRed}, ${scndryGreen}, ${scndryBlue}`}, 0.6)
      ), url(${BACKGROUND_URL});
      background-size: 100% 100%;
    }
    #dialog {
      height: 100vh;
      width: 100vw;
      position: absolute;
      top: 0;
      left: 0;
    }
    div {
      box-sizing: border-box;
    } 
  }
`;

export default GlobalStyles