import styled from "styled-components";

export const StyledGameStats = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5rem 10rem;
  /* align-items: flex-start; */
  font-size: ${({ theme }) => theme.fontSize.md};
`;
export const HeadersRow = styled.div`
  margin-bottom: 3rem;
  display: grid;
  grid-template-columns: 50% 50%;
  border-bottom: 1px solid black;
`;
export const DataRow = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  justify-items: left;
  margin-bottom: 0.5rem;
`;
export const DetailsWrapper = styled.div`
  width: 50%;
`;
export const TitleRow = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 5rem;
`;
export const DataWrapper = styled.div`
  display: flex;
`;

export const DialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

export const TasksForUser = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 15rem;
  overflow-y: auto;
`;
