import { FunctionComponent } from "react";
import { ButtonColor, ButtonVariant } from "../types";
import { IconButton, StyledButton, Tooltip } from "./Button.style";

interface Props {
  onClick: () => void;
  disabled?: boolean;
  color?: ButtonColor;
  variant?: ButtonVariant;
  tooltip?: string;
}

export const Button: FunctionComponent<Props> = (props) => {
  const { onClick, children, disabled, variant, color, tooltip } = props;

  return (
    <>
      {variant !== "icon" ? (
        <StyledButton
          onClick={onClick}
          disabled={disabled ?? false}
          variant={variant ?? "contained"}
          color={color ?? "primary"}
        >
          {children}
        </StyledButton>
      ) : (
        <IconButton
          onClick={onClick}
          color={color ?? "primary"}
          disabled={disabled ?? false}
        >
          {tooltip ? <Tooltip>{tooltip}</Tooltip> : null}
          {children}
        </IconButton>
      )}
    </>
  );
};
