import styled from "styled-components";
import { variables } from "./core/styles/variables";
const { breakpoints } = variables;

export const ContentWrapper = styled.div`
  height: calc(100vh - 19.8rem);
  @media (max-width: ${breakpoints.sm}) {
    height: calc(100vh);
  }
  color: ${({ theme }) => theme.color.primary};
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 1rem;
    border-radius: 5px;
    &-track {
      background-color: ${({ theme }) => theme.color.primary};
      border-radius: 5px;
    }
    &-thumb {
      background-color: ${({ theme }) => theme.color.secondary};
      border-radius: 5px;
    }
  }
`;
