import {
  BebokWrapper,
  ContentWrapper,
  Header,
  StyledBebok,
  StyledBezkost,
  StyledBorowy,
  StyledGamePage,
  StyledKoszka,
  StyledKuk,
  StyledLotawiec,
  StyledPolednica,
  StyledSkarbnik,
  StyledSwicorz,
  StyledSwietle,
  StyledUtopek,
  StyledZmora,
  TextWrapper,
} from "./GamePage.style";
import { Button } from "../../components/button/Button";
import { useHistory } from "react-router-dom";
import {
  BEBOK_URL,
  BEZKOST_URL,
  BOROWY_URL,
  KOSZKA_URL,
  KUK_URL,
  LOTAWIEC_URL,
  POLEDNICA_URL,
  SKARBNIK_URL,
  SWICORZ_URL,
  SWIETLE_URL,
  UTOPEK_URL,
  ZMORA_URL,
} from "./constants";
import { useContext } from "react";
import UIContext from "../../contexts/ui/UIContext";
import { bebokiContent } from "../../core/sites-content";
import AuthContext from "../../contexts/auth/AuthContext";
interface Props {}
const {
  bebok,
  bezkost,
  borowy,
  koszka,
  kuk,
  lotawiec,
  polednica,
  skarbnik,
  swicorz,
  swietle,
  utopek,
  zmora,
  description,
} = bebokiContent;

export const GamePage = (props: Props) => {
  const history = useHistory();
  const { showBebok } = useContext(UIContext);
  const { loggedIn } = useContext(AuthContext);

  return (
    <StyledGamePage>
      <Header>
        <Button onClick={() => history.push("/")}>Wróć</Button>
      </Header>
      <ContentWrapper>
        <TextWrapper>
          <div>
            <div>{description.intro}</div>
            <div>{description.description}</div>
            <div>
              Czy wiesz jak wcześniej nazywała się Kopalnia Marcel? Emma.
              Dlaczego? <br />
              Historia (miejscami) prawdziwa zaczęła się wczesnym latem 1856
              roku, kiedy na świat przyszła córka Ferdynanda Wodacka – Emma.
              Ferdynand był najznakomitszym wiertniczym w całym okręgu
              rybnickim, który razem ze swoimi przyjaciółmi znalazł pokłady
              węgla na terenie dzisiejszego Radlina. Po uzyskaniu stosownych
              pozwoleń ruszyły prace na polach naszego miasta. Kopalnia rosła w
              oczach. Ferdynand Wodack postanowił ją nazwać imieniem swojej
              córki Emmy. <br />
              Historia ta nie kończy się w tym miejscu. Co działo się później?
              Działo się… bardzo wiele. Kliknij w link. Być może rąbka tajemnicy
              uchyli Mateusz Łysek…
              <br />
              <br />
              <a
                href="https://www.youtube.com/watch?v=UuHiB8o3RIk"
                target="_blank"
                rel="noreferrer"
              >
                https://www.youtube.com/watch?v=UuHiB8o3RIk
              </a>
              <br />
              <br />
              Gotowy na przygodę? Świetnie! Znajdź naszyjnik Emmy! Ale zanim
              wyruszysz w drogę – kilka wskazówek: <br />
              1. Zarejestruj się na stronie:
              <a
                href="http://www.gramiejskaradlin.com/rejestracja"
                target="_blank"
                rel="noreferrer"
              >
                http://www.gramiejskaradlin.com/rejestracja
              </a>
              <br />
              2. Załóż wygodne buty. <br />
              3. Zabierz ze sobą tablet – to najbardziej komfortowa opcja. Ze
              smartfonem w ręce również dasz radę, ale będzie nieco trudniej.
              Wybór należy do Ciebie! <br />
              4. Pamiętaj, aby mieć przy sobie kartkę oraz coś do pisania.
              Wspomniane rzeczy mogą się przydać... <br />
              5. Zaloguj się na stronie:
              http://www.gramiejskaradlin.com/logowanie <br />
              6. Ruszaj w drogę! Pierwszym punktem jest Tężnia w Radlinie. Tam
              skieruj swoje kroki.
              <br />
              7. Bądź uważny – jeśli dotrzesz do punktu, musisz odnaleźć ukryty
              kod QR. Rozejrzyj się dokładnie! <br />
              8. Zeskanuj kod QR, następnie kliknij w link, który przeniesie Cię
              do zadania. <br />
              9. Wykonaj zadanie, a otrzymasz hasło. <br />
              10. Wpisz hasło na stronie gry miejskiej i udaj się do następnego
              punktu. <br />
              11. Uważaj na beboki! To podstępne stworzenia! <br />
              12. Baw się dobrze! 3, 2, 1… Start!
              <br />
              Widzimy się na mecie!
            </div>
          </div>
          {loggedIn && (
            <Button
              onClick={() => history.push("/zadania")}
              children="Zadania"
            />
          )}
        </TextWrapper>
        <BebokWrapper>
          <StyledBebok
            onClick={() => showBebok(bebok.name, bebok.description)}
            src={BEBOK_URL}
            alt="bebok"
          />
          <StyledBezkost
            onClick={() => showBebok(bezkost.name, bezkost.description)}
            src={BEZKOST_URL}
            alt="bezkost"
          />
          <StyledBorowy
            onClick={() => showBebok(borowy.name, borowy.description)}
            src={BOROWY_URL}
            alt="borowy"
          />
          <StyledKoszka
            onClick={() => showBebok(koszka.name, koszka.description)}
            src={KOSZKA_URL}
            alt="koszka"
          />
          <StyledKuk
            onClick={() => showBebok(kuk.name, kuk.description)}
            src={KUK_URL}
            alt="kuk"
          />
          <StyledLotawiec
            onClick={() => showBebok(lotawiec.name, lotawiec.description)}
            src={LOTAWIEC_URL}
            alt="lotawiec"
          />
          <StyledPolednica
            onClick={() => showBebok(polednica.name, polednica.description)}
            src={POLEDNICA_URL}
            alt="polednica"
          />
          <StyledSkarbnik
            onClick={() => showBebok(skarbnik.name, skarbnik.description)}
            src={SKARBNIK_URL}
            alt="skarbnik"
          />
          <StyledSwicorz
            onClick={() => showBebok(swicorz.name, swicorz.description)}
            src={SWICORZ_URL}
            alt="swicorz"
          />
          <StyledSwietle
            onClick={() => showBebok(swietle.name, swietle.description)}
            src={SWIETLE_URL}
            alt="swietle"
          />
          <StyledUtopek
            onClick={() => showBebok(utopek.name, utopek.description)}
            src={UTOPEK_URL}
            alt="utopek"
          />
          <StyledZmora
            onClick={() => showBebok(zmora.name, zmora.description)}
            src={ZMORA_URL}
            alt="zmora"
          />
        </BebokWrapper>
      </ContentWrapper>
    </StyledGamePage>
  );
};
