import React, { useContext, useEffect, useState } from "react";
import {
  AnnouncementsWrapper,
  Header,
  NoAnnouncements,
  StyledAnnouncementsList,
} from "./BlogPostList.style";
import { AxiosError } from "axios";
import UIContext from "../../contexts/ui/UIContext";
import sanityClient from "../../api/sanity/sanityClient";
import { BlogPosts } from "./BlogPost.types";
import { SingleAnnouncement } from "../homepage/announcements-list/single-announcement/SingleAnnouncement";

interface Props {}

export const BlogPostList = (props: Props) => {
  const { showLoading, showSnackbar } = useContext(UIContext);
  const [blogPosts, setBlogPosts] = useState<BlogPosts>([]);
  useEffect(() => {
    showLoading(true);
    sanityClient
      .fetch(
        `*[_type == "post" && !(_id in path('drafts.**'))] | order(publishedAt desc)`
      )
      .then((response: BlogPosts) => {
        setBlogPosts(response);
        showLoading(false);
      })
      .catch((err: AxiosError) => {
        showLoading(false);
        showSnackbar("error", err.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledAnnouncementsList>
      <Header>Blog</Header>
      <AnnouncementsWrapper>
        {blogPosts.length !== 0 ? (
          blogPosts.map((blogPost, index: number) => {
            return <SingleAnnouncement key={index} announcement={blogPost} />;
          })
        ) : (
          <NoAnnouncements>Brak wpisów na blogu</NoAnnouncements>
        )}
      </AnnouncementsWrapper>
    </StyledAnnouncementsList>
  );
};
