import { Theme } from 'styled-components';

export const theme: Theme = {
  color: {
    white: '#fff',
    black: '#000',
    primary: '#7D3F30',
    secondary: '#F4B23C',
    tertiary: '#B9C0C4',
    error: '#e23636',
    success: '#82dd55',
    info: '#4a90e2',
    warning: '#edb95e'
  },
  fontSize: {
    head1: '6.8rem',
    head2: '5rem',
    head3: '4rem',
    xlg: '2.3rem',
    lg: '2rem',
    md: '1.8rem',
    sm: '1.4rem',
    xsm: '1.2rem',
  },
  fontWeight: {
    fontRegular: 400,
    fontLight: 300,
    fontThin: 200,
    fontMedium: 500,
    fontSemiBold: 600,
    fontBold: 700,
    fontExtraBold: 800,
  }
}