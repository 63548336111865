import imageUrlBuilder from '@sanity/image-url'
import { ImageUrlBuilder } from '@sanity/image-url/lib/types/builder';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import sanityClient from './sanityClient'

const imgBuilder = imageUrlBuilder(sanityClient);

function urlFor(source: SanityImageSource): ImageUrlBuilder{
  return imgBuilder.image(source);
}

export default urlFor;