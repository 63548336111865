import { FaSpinner } from "react-icons/fa";
import { StyledLoading } from "./Loading.style";

export const Loading = () => {
  return (
    <StyledLoading>
      <FaSpinner />
    </StyledLoading>
  );
};
