import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import sanityClient from "../../api/sanity/sanityClient";
import { BlockContent, News } from "../../api/sanity/schema";
import { Button } from "../../components/button/Button";
import UIContext from "../../contexts/ui/UIContext";
import {
  Header,
  StyledAnnounementPage,
  StyledPortableText,
} from "./StyledAnnouncementPage.styled";

const AnnouncementPage = () => {
  const [announcement, setAnnouncement] = useState<News>();

  const { showLoading, showSnackbar } = useContext(UIContext);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    showLoading(true);
    sanityClient
      .fetch(`*[_type == "news" && (_id == "${id}")]`)
      .then((response) => {
        setAnnouncement(response.at(0));
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
        showSnackbar("error", err.message);
      });
  }, [id, showLoading, showSnackbar]);

  return announcement ? (
    <StyledAnnounementPage>
      <Button onClick={() => history.push("/aktualnosci")}>Wróć</Button>
      <Header>
        <span>{announcement.title}</span>
        &nbsp;-&nbsp;
        <span>
          {new Date(announcement.publishedAt as string).toLocaleDateString(
            "pl-PL"
          )}
        </span>
      </Header>
      <StyledPortableText
        content={announcement.body as BlockContent}
        dataset={process.env.REACT_APP_SANITY_DATASET}
        projectId={process.env.REACT_APP_SANITY_CLIENT_ID}
        serializers={{
          link: (props: { href: string; children: [] }) => {
            const { href, children } = props;
            return href.includes("youtube") ? (
              <iframe
                title="youtube"
                width="420"
                height="315"
                src={href.replace("watch", "embed")}
              ></iframe>
            ) : (
              <a href={href}>{children.at(0)}</a>
            );
          },
        }}
      />
    </StyledAnnounementPage>
  ) : null;
};

export default AnnouncementPage;
