import styled from "styled-components";

export const StyledPlayButton = styled.div`
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize.xlg};
  padding: 2rem 5rem;
  border: 2px solid ${({ theme }) => theme.color.primary};
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  color: ${({ theme }) => theme.color.primary};
  border-radius: 2rem;
  box-shadow: 0.1rem 0.1rem 0.6rem ${({ theme }) => theme.color.primary};
  transition: all 0.4s ease;

  &:hover {
    & > :nth-child(3) {
      opacity: 1;
      transform: translateX(-1rem) scale(1.5);
    }
    box-shadow: 0.3rem 0.3rem 0.8rem ${({ theme }) => theme.color.primary};
    transform: translateX(-0.2rem) translateY(-0.2rem);
  }
  & > :nth-child(3) {
    opacity: 0;
    transform: translateX(2rem) translateY(4rem) scale(1.5);
    transition: all 0.4s ease;
    color: ${({ theme }) => theme.color.secondary};
  }
`;

export const ButtonText = styled.span`
  margin-right: 4rem;
  font-weight: ${({ theme }) => theme.fontWeight.fontThin};
  letter-spacing: 0.1rem;
  text-transform: uppercase;
`;
