import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ContentWrapper } from "./App.style";
import Task from "./components/task/Task";
import { About } from "./containers/about/About";
import { AdminPage } from "./containers/adminpage/AdminPage";
import AnnouncementPage from "./containers/announcement-page/AnnouncementPage";
import BlogPostPage from "./containers/blogpost-page/BlogPostPage";
import { BlogPostList } from "./containers/blogposts-list/BlogPostList";
import { Contact } from "./containers/contact/Contact";
import { GamePage } from "./containers/gamepage/GamePage";
import { AnnouncementsList } from "./containers/homepage/announcements-list/AnnouncementsList";
import { Homepage } from "./containers/homepage/Homepage";
import { Project } from "./containers/project/Project";
import UserTasks from "./containers/user-tasks/UserTasks";
import { AuthProvider } from "./contexts/auth/AuthContext";
import { UIProvider } from "./contexts/ui/UIContext";
import { Footer } from "./core/components/footer/Footer";
import { Login } from "./core/components/login/Login";
import { Navbar } from "./core/components/navbar/Navbar";
import { Register } from "./core/components/register/Register";
import { Sidebar } from "./core/components/sidebar/Sidebar";
import GlobalStyles from "./core/styles/globalStyles";
import { variables } from "./core/styles/variables";
import useWindowDimensions from "./hooks/useWindowDimensions";

const { breakpoints } = variables;

function App() {
  const { width } = useWindowDimensions();

  return (
    <>
      <GlobalStyles />
      <UIProvider>
        <AuthProvider>
          <Router>
            <Switch>
              <Route exact path="/gra">
                <GamePage />
              </Route>
              <Route path="/">
                {width <= parseInt(breakpoints.md) ? <Sidebar /> : <Navbar />}
                <ContentWrapper>
                  <Switch>
                    <Route exact path="/rejestracja">
                      <Register />
                    </Route>
                    <Route exact path="/logowanie">
                      <Login />
                    </Route>
                    <Route exact path="/">
                      <Homepage />
                    </Route>
                    <Route exact path="/oprojekcie">
                      <Project />
                    </Route>
                    <Route exact path="/kontakt">
                      <Contact />
                    </Route>
                    <Route exact path="/onas">
                      <About />
                    </Route>
                    <Route path="/admin">
                      <AdminPage />
                    </Route>
                    <Route exact path="/aktualnosci">
                      <AnnouncementsList />
                    </Route>
                    <Route exact path="/zadania">
                      <UserTasks />
                    </Route>
                    <Route path="/zadania/:id">
                      <Task />
                    </Route>
                    <Route exact path="/aktualnosci/:id">
                      <AnnouncementPage />
                    </Route>
                    <Route exact path="/blog">
                      <BlogPostList />
                    </Route>
                    <Route path="/blog/:id">
                      <BlogPostPage />
                    </Route>
                  </Switch>
                </ContentWrapper>
                <Footer />
              </Route>
            </Switch>
          </Router>
        </AuthProvider>
      </UIProvider>
    </>
  );
}

export default App;
