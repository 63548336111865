import styled from "styled-components";
import { variables } from "../../styles/variables";
const { breakpoints } = variables;

export const StyledFooter = styled.div`
  position: absolute;
  bottom: 0;
  background-color: ${({ theme }) => theme.color.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3.3rem;
  cursor: pointer;
  & > span {
    display: inline-block;
    width: 100%;
    font-family: "Arial";
    font-size: 16px;
    text-align: center;
    color: ${({ theme }) => theme.color.black};
    @media (max-width: ${breakpoints.xs}) {
      font-size: 12px;
    }
  }

  @media (max-width: ${breakpoints.lg}) {
    flex-direction: column;
    padding: 1.5rem 3.3rem;
  }
  @media (max-width: ${breakpoints.sm}) {
    position: inherit;
  }
  @media (max-width: ${breakpoints.xs}) {
    padding: 1rem 1rem;
  }
`;
export const SmallerLogos = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${breakpoints.md}) {
    margin-bottom: 1rem;
  }
  @media (max-width: ${breakpoints.xs}) {
    margin-bottom: 0;
  }
  & > img {
    height: 7rem;
    &:not(:last-child) {
      margin-right: 5rem;
      @media (max-width: ${breakpoints.sm}) {
        margin-right: 2rem;
      }
      @media (max-width: ${breakpoints.xs}) {
        margin-right: 0.5rem;
      }
    }
    @media (max-width: ${breakpoints.xlg}) {
      & {
        height: 6rem;
      }
    }
    @media (max-width: ${breakpoints.xs}) {
      height: 5rem;
    }
  }
`;
export const BiggerLogos = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: ${breakpoints.lg}) {
    justify-content: center;
    padding-left: 0;
  }
  & > img {
    height: 10rem;
    &:not(:last-child) {
      margin-right: 5rem;
      @media (max-width: ${breakpoints.xs}) {
        margin-right: 0;
      }
      @media (max-width: ${breakpoints.sm}) {
        margin-right: 2rem;
      }
    }
    &:nth-child(2) {
      margin-bottom: 1rem;
    }
    @media (max-width: ${breakpoints.xlg}) {
      & {
        height: 8rem;
      }
    }
    @media (max-width: ${breakpoints.xs}) {
      & {
        height: 6rem;
      }
    }
  }
  & > span {
    display: inline-block;
    width: 50%;
    font-family: "Arial";
    font-size: 16px;
    text-align: center;
    color: ${({ theme }) => theme.color.black};
  }
`;
