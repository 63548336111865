import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import sanityClient from "../../api/sanity/sanityClient";
import { BlockContent, Tasks } from "../../api/sanity/schema";
import { TasksService } from "../../api/tasks/tasksService";
import { TaskRequest } from "../../api/tasks/types";
import { UserType } from "../../api/types";
import AuthContext from "../../contexts/auth/AuthContext";
import UIContext from "../../contexts/ui/UIContext";
import { Button } from "../button/Button";
import { Checkbox } from "../checkbox/Checkbox";
import { Input } from "../input/Input";
import { InputType } from "../types";
import {
  AnswerWrapper,
  Header,
  StyledPortableText,
  StyledTask,
} from "./Task.style";

type Props = {};
interface FormData {
  answer: string | boolean;
}

const Task = (props: Props) => {
  const { id } = useParams<{ id: string }>();
  const [task, setTask] = useState<Tasks>();
  const { showLoading, showSnackbar, showDialog, closeDialog } =
    useContext(UIContext);
  const {
    user: { id: userId, email, type },
    loggedIn,
  } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    showLoading(true);
    sanityClient
      .fetch(`*[_type == "tasks" && (_id == "${id}")]`)
      .then((response) => {
        setTask(response.at(0));
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
        showSnackbar("error", err.message);
      });
  }, [id, showLoading, showSnackbar]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const handleCorrect = (submittedAnswer: string | boolean) => {
    closeDialog();
    const payloadData: TaskRequest = {
      email,
      user_id: userId,
      task_id: id,
      task_name: task?.title as string,
      correct_answer:
        task?.answer_text ?? (task?.answer_boolean as string | boolean),
      submitted_answer: submittedAnswer,
    };
    TasksService.addTask(payloadData)
      .then(() => {
        showSnackbar("success", "Odpowiedź została dodana");
        history.push("/zadania");
      })
      .catch((err) => {
        showLoading(false);
        showSnackbar("error", "Wystąpił błąd");
      });
  };

  const handleWrong = () => {
    closeDialog();
  };

  const handleDialog = (
    isCorrect: boolean,
    submittedAnswer: string | boolean
  ) => {
    showDialog(
      "info",
      <div>
        {isCorrect === true ? (
          <StyledPortableText
            content={task?.response as BlockContent}
            dataset={process.env.REACT_APP_SANITY_DATASET}
            projectId={process.env.REACT_APP_SANITY_CLIENT_ID}
            serializers={{
              link: (props: { href: string; children: [] }) => {
                const { href, children } = props;
                return href.includes("youtube") ? (
                  <iframe
                    title="youtube"
                    width="420"
                    height="315"
                    src={href.replace("watch", "embed")}
                  ></iframe>
                ) : (
                  <a href={href}>{children.at(0)}</a>
                );
              },
            }}
          />
        ) : (
          "Odpowiedź błędna, spróbuj jeszcze raz"
        )}
      </div>,
      isCorrect ? () => handleCorrect(submittedAnswer) : handleWrong
    );
  };

  const onSubmit = (formData: FormData) => {
    const answerType = task?.answer_type;
    const answer = formData.answer;
    let correctAnswer;
    if (answerType === "text") {
      correctAnswer =
        task?.answer_text?.match(new RegExp(answer as string, "ig")) !== null
          ? true
          : false;
    } else {
      correctAnswer = task?.answer_boolean === answer;
    }
    handleDialog(correctAnswer, answer);
  };

  return task !== undefined && loggedIn ? (
    <StyledTask>
      <Button
        onClick={
          type === UserType.ADMIN
            ? () => history.push("/admin/quests")
            : () => history.push("/zadania")
        }
      >
        Wróć
      </Button>
      <Header>
        <span>{task.title}</span>
        &nbsp;-&nbsp;
        <span>
          {new Date(task._createdAt as string).toLocaleDateString("pl-PL", {})}
        </span>
      </Header>
      <StyledPortableText
        content={task.body as BlockContent}
        dataset={process.env.REACT_APP_SANITY_DATASET}
        projectId={process.env.REACT_APP_SANITY_CLIENT_ID}
        serializers={{
          link: (props: { href: string; children: [] }) => {
            const { href, children } = props;
            return href.includes("youtube") ? (
              <iframe
                title="youtube"
                width="420"
                height="315"
                src={href.replace("watch", "embed")}
              ></iframe>
            ) : (
              <a href={href}>{children.at(0)}</a>
            );
          },
        }}
      />
      {type === UserType.USER ? (
        <AnswerWrapper>
          {task.answer_type === "text" ? (
            <Input
              type={InputType.TEXT}
              register={register}
              label="Odpowiedź"
              name="answer"
              id="answer"
              error={errors.answer}
            />
          ) : (
            <Checkbox
              register={register}
              label="Odpowiedź"
              name="answer"
              value={false}
              variant="classic"
            />
          )}
          <Button onClick={handleSubmit(onSubmit)}>Sprawdź odpowiedź</Button>
        </AnswerWrapper>
      ) : null}
    </StyledTask>
  ) : (
    <h1>Nie masz dostępu do tej strony, zaloguj się</h1>
  );
};

export default Task;
