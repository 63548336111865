import styled from "styled-components";

export const StyledCheckboxContainer = styled.div`
  font-size: ${({ theme }) => theme.fontSize.sm};
  display: flex;
  align-items: center;
`;

export const StyledCheckbox = styled.label`
  margin-left: 1rem;
  height: 2.5rem;
  width: 2.5rem;
  border: 1px solid gray;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  & > :first-child {
    color: ${({ theme }) => theme.color.secondary};
    transform: scale(1.2);
  }
`;

export const IconCheckbox = styled.label`
  cursor: pointer;
  & > * {
    transform: scale(1.3);
    margin-left: 1rem;
    border: 1px solid gray;
    padding: 0.5rem;
  }
`;

export const HiddenCheckbox = styled.input`
  visibility: hidden;
`;
