import { useContext } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { AuthService } from "../../../api/auth/authService";
import { Input } from "../../../components/input/Input";
import { InputType } from "../../../components/types";
import {
  AlreadyHaveAccountInfo,
  FormWrapper,
  StyledRegister,
} from "./Register.style";
import { variables } from "../../utils";
import { RegisterForm } from "./types";
import AuthContext from "../../../contexts/auth/AuthContext";
import UIContext from "../../../contexts/ui/UIContext";
import { AxiosError } from "axios";
import { Button } from "../../../components/button/Button";

interface Props {}

export const Register = (props: Props) => {
  const { emailRegex } = variables;
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<RegisterForm>({ mode: "onBlur", reValidateMode: "onBlur" });
  const { handleRegister } = useContext(AuthContext);
  const { showSnackbar, showLoading } = useContext(UIContext);
  const history = useHistory();
  const onSubmitHandle = (data: RegisterForm) => {
    showLoading(true);
    AuthService.signUp(data)
      .then((response) => {
        handleRegister(response);
        showLoading(false);
        showSnackbar("success", response.data.message);
        history.push("/logowanie");
      })
      .catch((error: AxiosError) => {
        showLoading(false);
        showSnackbar("error", error.response?.data.message);
      });
  };

  return (
    <StyledRegister>
      <h1>Rejestracja</h1>
      <FormWrapper>
        <Input
          type={InputType.TEXT}
          label="Nazwa użytkownika"
          register={register}
          name="nickname"
          id="nickname"
          error={errors.nickname}
        />
        <Input
          type={InputType.EMAIL}
          label="Email"
          register={register}
          name="email"
          id="email"
          error={errors.email}
          options={{
            pattern: {
              value: emailRegex,
              message: "Email jest nieprawidłowy",
            },
            required: { value: true, message: "To pole jest wymagane" },
          }}
        />
        <Input
          type={InputType.PASSWORD}
          label="Hasło"
          register={register}
          name="password"
          id="password"
          options={{
            required: { value: true, message: "To pole jest wymagane" },
            minLength: {
              value: 5,
              message: "Hasło musi mieć conajmniej 5 znaków",
            },
          }}
          error={errors.password}
        />
        <Input
          type={InputType.PASSWORD}
          label="Powtórz hasło"
          register={register}
          name="secondPassword"
          id="secondPassword"
          options={{
            required: { value: true, message: "To pole jest wymagane" },
            validate: (value) =>
              value === getValues("password") || "Hasła nie są takie same",
          }}
          error={errors.secondPassword}
        />
      </FormWrapper>
      <Button color="secondary" onClick={handleSubmit(onSubmitHandle)}>
        Zarejestruj
      </Button>
      <AlreadyHaveAccountInfo>
        <span>Masz już konto?</span>
        <Button onClick={() => history.push("/logowanie")}>Zaloguj się</Button>
      </AlreadyHaveAccountInfo>
    </StyledRegister>
  );
};
