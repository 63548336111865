import { AxiosError } from "axios";
import { useContext, useEffect, useState } from "react";
import sanityClient from "../../api/sanity/sanityClient";
import UIContext from "../../contexts/ui/UIContext";
import {
  ImageWrapper,
  ProjectContent,
  ProjectDescription,
  ProjectHeader,
  SignsImage,
  StyledProject,
} from "./Project.style";

interface Props {}

const SIGNS_URL = `${process.env.PUBLIC_URL}/assets/flaga-godlo.png`;
export const Project = (props: Props) => {
  const [projectContent, setProjectContent] = useState<any[]>([]);
  const { showLoading, showSnackbar } = useContext(UIContext);

  useEffect(() => {
    showLoading(true);
    sanityClient
      .fetch(`*[_type == "pages" && title == "aboutProject"]`)
      .then((data) => {
        setProjectContent(data[0].content);
        showLoading(false);
      })
      .catch((err: AxiosError) => {
        showLoading(false);
        showSnackbar("error", err.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledProject>
      <ProjectHeader>O projekcie</ProjectHeader>
      <ProjectContent>
        <ImageWrapper>
          <SignsImage src={SIGNS_URL} />
        </ImageWrapper>
        {projectContent &&
          projectContent.map((contentRow, index) => (
            <ProjectDescription key={index}>
              {contentRow.children[0].text}
            </ProjectDescription>
          ))}
      </ProjectContent>
    </StyledProject>
  );
};
