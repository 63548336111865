import { useHistory } from "react-router-dom";
import { Banner, StyledHomepage } from "./Homepage.style";
import { PlayButton } from "./play-button/PlayButton";

interface Props {}

export const Homepage = (props: Props) => {
  const history = useHistory();
  return (
    <StyledHomepage>
      <Banner>
        <PlayButton onClick={() => history.push("/gra")} />
      </Banner>
    </StyledHomepage>
  );
};
