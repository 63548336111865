import PortableText from "react-portable-text";
import styled from "styled-components";
import { variables } from "../../core/styles/variables";
const { breakpoints } = variables;

export const StyledTask = styled.div`
  margin: 3rem 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > :first-child {
    margin-bottom: 2rem;
  }
`;
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.xlg};
  border-bottom: 3px solid ${({ theme }) => theme.color.secondary};
  padding: 1rem 2rem;
`;

export const StyledPortableText = styled(PortableText)`
  @media (max-width: ${breakpoints.sm}) {
    width: 100%;
  }
  figure {
    margin: 0;
    text-align: center;
    & > img {
      max-width: 100%;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    iframe {
      width: 312px;
      height: 207px;
    }
  }
  & > * {
    font-size: ${({ theme }) => theme.fontSize.md};
  }
`;
export const Content = styled.div`
  margin-bottom: 3rem;
  & > * {
    font-size: ${({ theme }) => theme.fontSize.md};
  }
`;
export const InfoWrapper = styled.div`
  font-size: ${({ theme }) => theme.fontSize.sm};
`;
export const AnswerWrapper = styled.div``;
