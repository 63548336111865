import styled from "styled-components";
import { variables } from "../../core/styles/variables";

interface StyledInputProps {
  error: boolean;
}
const { breakpoints } = variables;

export const StyledLabel = styled.label`
  margin-right: 1rem;
  font-size: ${({ theme }) => theme.fontSize.sm};
`;

export const StyledInput = styled.input<StyledInputProps>`
  width: 30rem;
  height: 4rem;
  outline: none;
  font-size: ${({ theme }) => theme.fontSize.md};
  border: ${({ error }) => (error ? "1.5px solid red" : "1.5px solid grey")};
  border-radius: 0.5rem;
  padding-left: 0.6rem;
`;

export const StyledInputWrapper = styled.div`
  width: 50rem;
  @media (max-width: ${breakpoints.sm}) {
    width: 30rem;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

export const StyledError = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xsm};
  padding-top: 0.2rem;
  color: red;
`;
