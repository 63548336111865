import React, { useContext, useEffect, useState } from "react";
import { FaRegEye } from "react-icons/fa";
import sanityClient from "../../api/sanity/sanityClient";
import { BlockContent } from "../../api/sanity/schema";
import { Task } from "../../api/tasks/types";
import { Button } from "../../components/button/Button";
import UIContext from "../../contexts/ui/UIContext";
import { ActionsWrapper } from "../adminpage/Tasks/SingleTask/SingleTask.style";
import { StyledPortableText } from "../announcement-page/StyledAnnouncementPage.styled";
import { StyledSingleTask } from "./UserTasks.styled";

type Props = {
  task: Task;
};

const SingleUserTask = ({ task }: Props) => {
  const { task_name, submitted_answer, createdAt, task_id } = task;
  const [taskResponse, setTaskResponse] = useState<BlockContent>();
  const { showDialog, showLoading, showSnackbar } = useContext(UIContext);

  useEffect(() => {
    showLoading(true);
    sanityClient
      .fetch(`*[_type == "tasks" && (_id == "${task_id}")]`)
      .then((response) => {
        setTaskResponse(response.at(0).response);
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
        showSnackbar("error", err.message);
      });
  }, [task_id, showLoading, showSnackbar]);

  const handlePreview = () =>
    showDialog(
      "info",
      <StyledPortableText
        content={taskResponse as BlockContent}
        dataset={process.env.REACT_APP_SANITY_DATASET}
        projectId={process.env.REACT_APP_SANITY_CLIENT_ID}
      />
    );

  return (
    <StyledSingleTask>
      <span>{task_name}</span>
      <span>{submitted_answer}</span>
      <span>{new Date(createdAt).toLocaleString("pl-PL")}</span>
      <ActionsWrapper>
        <Button onClick={handlePreview} variant="icon">
          <FaRegEye />
        </Button>
      </ActionsWrapper>
    </StyledSingleTask>
  );
};

export default SingleUserTask;
