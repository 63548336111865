const primaryRgb = {
  red: 123,
  green: 63,
  blue: 48
}

const secondaryRgb = {
  red: 244,
  green: 178,
  blue: 60
}

const breakpoints = {
  xs: '420px',
  sm: '768px',
  md: '992px',
  lg: '1224px',
  xlg: '1500px'
}

export const variables = {
  primaryRgb,
  secondaryRgb,
  breakpoints
}