import styled from "styled-components";
import { variables } from "../../core/styles/variables";
import { BACKGROUND_URL, MAP_URL } from "./constants";
const { breakpoints } = variables;

export const StyledGamePage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${BACKGROUND_URL});
  background-size: cover;
  background-position: center;
`;
export const Header = styled.div`
  display: flex;
  padding-top: 2rem;
  justify-content: center;
`;
export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: ${breakpoints.lg}) {
    flex-direction: column;
  }
`;
export const TextWrapper = styled.div`
  margin-top: 5rem;
  padding-left: 10%;
  width: 50%;
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.fontSize.lg};
  line-height: 3rem;
  & > div {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
  @media (max-width: ${breakpoints.lg}) {
    text-align: center;
    padding: 0 5rem;
    font-size: ${({ theme }) => theme.fontSize.md};
    line-height: 2.5rem;
    width: 100%;
  }
  @media (max-width: ${breakpoints.md}) {
    font-size: ${({ theme }) => theme.fontSize.md};
    line-height: 2rem;
  }
  @media (max-width: ${breakpoints.sm}) {
    font-size: ${({ theme }) => theme.fontSize.md};
    line-height: 2rem;
  }
  @media (max-width: 520px) {
    line-height: 1.5rem;
    font-size: ${({ theme }) => theme.fontSize.sm};
  }
`;
export const BebokWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 75rem;
  background-image: url(${MAP_URL});
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  @media (min-width: 1700px) {
    width: 65%;
  }
  @media (max-width: ${breakpoints.lg}) {
    height: 90rem;
    width: 100%;
  }
  @media (max-width: ${breakpoints.md}) {
    height: 70rem;
  }
  @media (max-width: ${breakpoints.sm}) {
    height: 70rem;
  }
  @media (max-width: 650px) {
    height: 60rem;
  }
  @media (max-width: 520px) {
    height: 50rem;
  }
  @media (max-width: ${breakpoints.xs}) {
    height: 40rem;
  }

  & > img {
    position: absolute;
    width: 9rem;
    height: auto;
    z-index: 3;
    cursor: pointer;
    transition: all 0.4s;
    &:hover {
      transform: scale(1.05);
    }
    @media (max-width: ${breakpoints.md}) {
      width: 9rem;
    }
    @media (max-width: ${breakpoints.sm}) {
      width: 8rem;
    }
    @media (max-width: 650px) {
      width: 6.5rem;
    }
    @media (max-width: 520px) {
      width: 5.5rem;
    }
    @media (max-width: ${breakpoints.xs}) {
      width: 5rem;
    }
  }
`;
export const StyledBebok = styled.img`
  bottom: 15%;
  right: 30%;
`;
export const StyledBezkost = styled.img`
  right: 49%;
  top: 20%;
`;
export const StyledBorowy = styled.img`
  right: 22%;
  bottom: 30%;
`;
export const StyledKoszka = styled.img`
  left: 45%;
  top: 49%;
`;
export const StyledKuk = styled.img`
  top: 45%;
  left: 13%;
`;
export const StyledLotawiec = styled.img`
  right: 37%;
  top: 32%;
`;
export const StyledPolednica = styled.img`
  top: 26%;
  left: 25%;
`;
export const StyledSkarbnik = styled.img`
  right: 30%;
  bottom: 40%;
`;
export const StyledSwicorz = styled.img`
  top: 15%;
  left: 30%;
`;
export const StyledSwietle = styled.img`
  left: 28%;
  top: 37%;
`;
export const StyledUtopek = styled.img`
  top: 22%;
  right: 40%;
`;
export const StyledZmora = styled.img`
  right: 51%;
  top: 35%;
`;
