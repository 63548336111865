export const bebokiContent = {
  description: {
    intro: 'Gra miejska – integracja i odkrywanie radlińskich supermocy jest zabawą stworzoną przez mieszkańców dla mieszkańców Radlina i okolic.',
    description: 'W wolnym czasie poznaj nasze miasto przez pryzmat fascynującej historii, która połączy teraźniejszość z przeszłością. Wspólna podróż z pewnością dostarczy wielu intrygujących informacji dotyczących Radlina!',
    start: `Aktywacja: 10 września 2022 roku, Plac Radlińskich Olimpijczyków. Start: godz. 15.00. Zgłoś swój udział: https://bit.ly/3PLBgZ4 W razie pytań zadzwoń do nas - 790 326 378.`
  },
  bebok: {
    name: "Bebok",
    description: "Mały, kudłaty złośliwy duch. Zamieszkiwał strychy lub piwnice. Uwielbiał jeść, co też skłaniało go do wychodzenia z ukrycia w poszukiwaniu jedzenia. Żeby uspokoić harce złośliwego duszka, trzeba było zostawić trochę mleka lub chleba na schodach w piwnicy, czy strychu.",
  },
  bezkost: {
    name: "Bezkost",
    description: "Podgatunek wampira. Nie miał kości, wyglądał jak worek, który wypełniał się krwią swoich ofiar. Bezkostna budowa ciała pozwalała mu się przeciskać przez najwęższe szczeliny, by tylko dostać się do ludzi.",
  },
  borowy: {
    name: "Borowy",
    description: "Prastary duch puszczy. Opiekun kniei i drzew. Pasterz leśnych zwierząt. Często zwany leszym. Wyglądał jak wysoki starzec. Twarz pokrywały liczne bruzdy a zamiast włosów wyrastały liście. Niechcianym gościom rzucał kłody pod nogi. Bywał też pomocny.",
  },
  koszka: {
    name: "Koszka",
    description: "Miały ciemną, gęstą sierść. Można je było pomylić z kotami domowymi, szczególnie z czarnymi. Kiedy wkradały się do domu, podmieniały w kołysce rumianego bobasa na swoje szkaradne, wychudzone szczenięta.",
  },
  kuk: {
    name: "Kuk",
    description: "Wielki ptak, który władał potężną magią. Król ptactwa, który chronił je przed atakami dzikich zwierząt. Pewnego dnia zniknął, jego królestwo upadło. Kukułki zostały wysłane na jego poszukiwania, dlatego nawołują „Ku-ku!”, a ich potomstwo wychowują inne ptaki.",
  },
  lotawiec: {
    name: "Lotawiec",
    description: "Pojawiał się wraz z nadejściem burzy. Uciekał przed uderzającymi piorunami, które celowały w niego. Często siadał na kościelnych wieżach, kaplicach, w które strzelały błyskawice powodując pożary. Gdy latał nad ziemią, wył i zawodził przeraźliwym krzykiem.",
  },
  polednica: {
    name: "Połednica",
    description: "Kobiecy demon polny. Powstał z kobiety, która zmarła przed ślubem. Odziana zwykle w biała suknię. Miała chudą, kościstą twarz, skołtunione włosy i długie łapy zakończone pazurami. Mieszkała wśród polnych kłosów. Gdy jakiś nieszczęśnik spotkał ją, łamała mu kończyny, dusiła go, czy paraliżowała.",
  },
  skarbnik: {
    name: "Skarbnik",
    description: "Prastary duch kopalni. Skarbnik zamieszkiwał najgłębsze tereny pod powierzchnią. Władał podziemną krainą. Tam zabierał dusze zmarłych górników, którzy zginęli w kopalni. Górnicy opowiadali o postaci starego mężczyzny, który pojawiał się znikąd w wydrążonych tunelach.",
  },
  swicorz: {
    name: "Świcorz",
    description: "Stwory, które pokazywały się w okolicach brzegów rzek, na mokradłach, torfowiskach. Miały długie ręce, a wielkie dłonie były zakończone podłużnymi pluchami. Każdy z nich świecił innym, jasnym światłem. Mówiono, że to dusze osób, które odbywają karę za jakieś przewinienia.",
  },
  swietle: {
    name: "Świetle",
    description: "Dobre duszki, które pomagały zgubionym ludziom. Pojawiały się pod postacią świetlistych kul. Sprowadzały wędrowców na właściwy trakt. Wystarczyło być miłym i uprzejmym dla nich i podziękować mówiąc „Bóg zapłać”. Jeśli ktoś był gburem, niewdzięcznikiem w stosunku do nich, dobre duszki zamieniały się w kupkę popiołu.",
  },
  utopek: {
    name: "Utopek",
    description: "Zamieszkiwał wszelkiego rodzaju akweny i cieki wodne. W zależności od wieku ubierał się na czerwono lub zielono albo całkowicie chodził bez ubrania. Utopek był demonem wodnym. Zwykle to dusza jakiegoś nieboszczka, który się utopił. Nie należał do przystojniaków, jego twarz była pomarszczona, czasami pokryta rybią łuską. ",
  },
  zmora: {
    name: "Zmora",
    description: "Dusze żywych ludzi, które opuszczały nocą ciało „nosiciela” by pić krew i dusić swoje ofiary. Przybierały postać wysokiej,  wychudzonej, przeźroczystej kobiety. Głowa była porośnięta gęstymi włosami, zaś oczy były ogromne.",
  }
}

