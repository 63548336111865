import styled from "styled-components";
import { SnackbarType } from "../../contexts/ui/types";

export interface StyledSnackbarProps {
  type: SnackbarType;
}

// const snackbarAnimation = keyframes`
//   0% { bottom: -5%, opacity: 0 }
//   100% { bottom: 0, opacity: 1 }
// `;

export const StyledSnackbar = styled.div<StyledSnackbarProps>`
  position: absolute;
  bottom: 1rem;
  left: 50%;
  display: flex;
  align-items: center;
  transform: translateX(-50%);
  background-color: ${(props) => props.theme.color[props.type]};
  color: ${({ theme }) => theme.color.white};
  border-radius: 0.3rem;
  padding: 1rem 3rem;
  font-size: ${({ theme }) => theme.fontSize.md};

  & > :first-child {
    margin-right: 1rem;
  }
`;
