import { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  if(!config.url?.includes('auth')){
    config.headers = {...config.headers, authorization: `Bearer ${JSON.parse(localStorage.getItem('token') as string)?? ''}`}
  }
  return config;
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  // console.error(`[request error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
}

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  return axiosInstance;
}