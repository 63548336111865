import { Button } from "../button/Button";
import {
  BebokDescription,
  BebokDialogWrapper,
  BebokName,
  StyledBebokDialog,
} from "./BebokDialog.style";

interface Props {
  name: string;
  description: string;
  onDismiss: () => void;
}

export const BebokDialog = (props: Props) => {
  const { name, description, onDismiss } = props;
  return (
    <>
      <BebokDialogWrapper />
      <StyledBebokDialog>
        <BebokName>{name}</BebokName>
        <BebokDescription>{description}</BebokDescription>
        <Button onClick={onDismiss}>Zamknij</Button>
      </StyledBebokDialog>
    </>
  );
};
