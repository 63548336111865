import axios from "axios";
import { setupInterceptorsTo } from "./interceptors";

const { REACT_APP_API_URL: apiUrl, REACT_APP_API_PORT: apiPort } = process.env;

const Api = axios.create({ 
  headers: {
    'Content-Type': 'application/json',
    // 'Access-Control-Allow-Origin': '*',
  },
  baseURL: `${apiUrl || 'http://localhost'}${apiPort ? `:${apiPort}` : ''}`,
  timeout: 5000,
})

setupInterceptorsTo(Api);

export default Api;