import styled from "styled-components";

export const StyledAnnouncementsList = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 10%;
`;
export const Header = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.head3};
  text-align: center;
  margin-bottom: 5rem;
`;
export const AnnouncementsWrapper = styled.div`
  padding: 4rem 0;
`;
export const NoAnnouncements = styled.h3`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.xlg};
`;
