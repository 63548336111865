import styled from "styled-components";
import { variables } from "../../core/styles/variables";
const { breakpoints } = variables;

export const StyledProject = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SignsImage = styled.img`
  width: 30rem;
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

export const ProjectDescription = styled.div`
  &:not(:last-child) {
    margin-bottom: 4rem;
  }
  font-size: ${({ theme }) => theme.fontSize.xlg};
  @media (max-width: ${breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.lg};
  }
  @media (max-width: ${breakpoints.md}) {
    font-size: ${({ theme }) => theme.fontSize.md};
  }
`;
export const ProjectEvents = styled.div`
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
  font-size: ${({ theme }) => theme.fontSize.xlg};
  @media (max-width: ${breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.lg};
  }
  @media (max-width: ${breakpoints.md}) {
    font-size: ${({ theme }) => theme.fontSize.md};
  }
`;
export const ProjectHeader = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.head3};
`;

export const ProjectContent = styled.div`
  margin: 0 10% 5rem 10%;
`;
