import { FieldError, RegisterOptions, UseFormRegister } from "react-hook-form";
import { InputType } from "../types";
import {
  StyledError,
  StyledInput,
  StyledInputWrapper,
  StyledLabel,
} from "./Input.style";

interface Props {
  label: string;
  type: InputType;
  name: string;
  register: UseFormRegister<any>;
  id: string;
  error: FieldError | undefined;
  options?: RegisterOptions;
}

export const Input = (props: Props) => {
  const { label, type, name, id, register, error, options } = props;

  return (
    <StyledInputWrapper>
      <div>
        <StyledLabel htmlFor={id}>{label}:</StyledLabel>
        <StyledInput
          error={error?.type ? true : false}
          type={type}
          {...register(name, options ?? {})}
          id={id}
        />
      </div>
      {error?.type ? (
        <StyledError>{error.message}</StyledError>
      ) : (
        <StyledError></StyledError>
      )}
    </StyledInputWrapper>
  );
};
