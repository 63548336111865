import { AxiosResponse } from "axios";
import Api from "..";
import { ExtendedResponseData } from "../auth/types";
import { UserType } from "../types";
import { UserResponse, UsersResponse } from "./types";

const usersUrl = '/users';

const getUser = (id: string): Promise<AxiosResponse<ExtendedResponseData<UserResponse>>> => {
  return Api.get(`${usersUrl}/user/${id}`);
}

const getUsers = (type: UserType): Promise<AxiosResponse<ExtendedResponseData<UsersResponse>>> => {
  return Api.get(`${usersUrl}/${type}`);
}

export const UsersService = {
  getUser,
  getUsers
}