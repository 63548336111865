import { useContext } from "react";
import { FormWrapper, GoToRegister, StyledLogin } from "./Login.style";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Input } from "../../../components/input/Input";
import { InputType } from "../../../components/types";
import { LoginForm } from "./types";
import { variables } from "../../utils";
import { AuthService } from "../../../api/auth/authService";
import AuthContext from "../../../contexts/auth/AuthContext";
import UIContext from "../../../contexts/ui/UIContext";
import { AxiosError } from "axios";
import { Button } from "../../../components/button/Button";

interface Props {}

export const Login = (props: Props) => {
  const { emailRegex } = variables;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginForm>();
  const { handleLogin } = useContext(AuthContext);
  const { showSnackbar, showLoading } = useContext(UIContext);
  const history = useHistory();
  const onSubmitHandle = (data: LoginForm) => {
    showLoading(true);
    AuthService.signIn(data)
      .then((response) => {
        handleLogin(response);
        showSnackbar("success", response.data.message);
        history.push("/");
        showLoading(false);
      })
      .catch((error: AxiosError) => {
        showLoading(false);
        showSnackbar("error", error.response?.data.message);
      });
  };

  return (
    <StyledLogin>
      <h1>Logowanie</h1>
      <FormWrapper>
        <Input
          label="Email"
          type={InputType.EMAIL}
          id="email"
          name="email"
          register={register}
          error={errors.email}
          options={{
            required: { value: true, message: "To pole jest wymagane" },
            pattern: { value: emailRegex, message: "Email jest nieprawidłowy" },
          }}
        />
        <Input
          type={InputType.PASSWORD}
          name="password"
          id="password"
          label="Hasło"
          error={errors.password}
          register={register}
          options={{
            required: { value: true, message: "To pole jest wymagane" },
          }}
        />
      </FormWrapper>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleSubmit(onSubmitHandle)}
      >
        Zaloguj
      </Button>
      <GoToRegister>
        <span>Nie masz konta?</span>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push("/rejestracja")}
        >
          Zarejestruj się
        </Button>
      </GoToRegister>
    </StyledLogin>
  );
};
