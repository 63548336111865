import styled, { keyframes } from "styled-components";
import { variables } from "../../core/styles/variables";
const { primaryRgb } = variables;
const rotateLoading = keyframes`
  from {
    transform: rotate(0deg) scale(5);
  }

  to {
    transform: rotate(360deg) scale(5);
  }
`;

export const StyledLoading = styled.div`
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(
    ${primaryRgb.red},
    ${primaryRgb.green},
    ${primaryRgb.blue},
    0.7
  );
  color: ${({ theme }) => theme.color.secondary};

  & > :first-child {
    position: absolute;
    top: 50%;
    left: 50%;
    animation: ${rotateLoading} 2s linear infinite;
  }
`;
