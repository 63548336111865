import PortableText from "react-portable-text";
import styled from "styled-components";

export const StyledSingleAnnouncement = styled.div`
  &:not(:last-child) {
    margin-bottom: 6rem;
  }
  border-left: 1px solid ${({ theme }) => theme.color.primary};
  border-right: 1px solid ${({ theme }) => theme.color.primary};
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
`;
export const Header = styled.div`
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: ${({ theme }) => theme.fontWeight.fontRegular};
  position: relative;
  margin-bottom: 2rem;
  &::after {
    content: "";
    position: absolute;
    width: 5rem;
    height: 3px;
    bottom: -5px;
    left: 0;
    background-color: ${({ theme }) => theme.color.secondary};
  }
`;
export const InfoWrapper = styled.div`
  font-size: ${({ theme }) => theme.fontSize.sm};
`;
export const DataRow = styled.div`
  margin-bottom: 1.5rem;
  font-style: italic;
  color: ${({ theme }) => theme.color.primary};
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;
export const StyledPortableText = styled(PortableText)`
  & > figure {
    margin: 0;
    width: 100%;
    & > img {
      max-width: 100%;
      text-align: center;
    }
  }
  max-height: 15rem;
  overflow: hidden;
  margin-bottom: 1rem;
`;
export const Content = styled.div`
  & > * {
    font-size: ${({ theme }) => theme.fontSize.md};
  }
`;
export const ButtonWrapper = styled.div``;
export const ReadMoreButton = styled.div`
  font-size: ${({ theme }) => theme.fontSize.sm};
  padding: 0.5rem 1rem;
  border: 1px solid ${({ theme }) => theme.color.primary};
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    border: 1px solid black;
  }
`;
export const Separator = styled.hr`
  width: 5rem;
  background-color: ${({ theme }) => theme.color.primary};
  height: 0.4rem;
  border: none;
`;
export const ImageWrapper = styled.div`
  text-align: center;
  & > img {
    max-width: 100%;
    text-align: center;
  }
`;
export const NewsImage = styled.img`
  max-height: 50rem;
  width: auto;
`;
