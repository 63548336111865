export enum UserType {
  ADMIN='ADMIN',
  USER='USER'
}

export interface User {
  id: string
  email: string
  nickname?: string;
  type: UserType
}