import styled from "styled-components";

export const StyledNavbar = styled.div`
  display: flex;
  padding: 2rem 10%;
  width: 100%;
  justify-content: space-between;
`;
export const NavWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const MainNav = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;
