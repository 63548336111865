export const BACKGROUND_URL = `${process.env.PUBLIC_URL}/assets/tlo.jpg`;
export const MAP_URL = `${process.env.PUBLIC_URL}/assets/mapa.png`;
export const BEBOK_URL = `${process.env.PUBLIC_URL}/assets/beboki/bebok.png`;
export const BEZKOST_URL = `${process.env.PUBLIC_URL}/assets/beboki/bezkost.png`; 
export const BOROWY_URL = `${process.env.PUBLIC_URL}/assets/beboki/borowy.png`; 
export const KOSZKA_URL = `${process.env.PUBLIC_URL}/assets/beboki/koszka.png`;
export const KUK_URL = `${process.env.PUBLIC_URL}/assets/beboki/kuk.png`; 
export const LOTAWIEC_URL = `${process.env.PUBLIC_URL}/assets/beboki/lotawiec.png`; 
export const POLEDNICA_URL = `${process.env.PUBLIC_URL}/assets/beboki/polednica.png`; 
export const SKARBNIK_URL = `${process.env.PUBLIC_URL}/assets/beboki/skarbnik.png`; 
export const SWICORZ_URL = `${process.env.PUBLIC_URL}/assets/beboki/swicorz.png`; 
export const SWIETLE_URL = `${process.env.PUBLIC_URL}/assets/beboki/swietle.png`; 
export const UTOPEK_URL = `${process.env.PUBLIC_URL}/assets/beboki/utopek.png`; 
export const ZMORA_URL = `${process.env.PUBLIC_URL}/assets/beboki/zmora.png`; 