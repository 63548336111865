import { AxiosError } from "axios";
import { useContext, useEffect, useState } from "react";
import sanityClient from "../../api/sanity/sanityClient";
import UIContext from "../../contexts/ui/UIContext";
import {
  AboutContent,
  AboutHeader,
  ContentPicker,
  ContentText,
  ImageWrapper,
  PickerImage,
  StyledAbout,
} from "./About.style";
import { ContentType, ContentDataType } from "./About.types";
const DIRTY_LOGO_RADLINIOKI = `${process.env.PUBLIC_URL}/assets/radlinioki-dirty.png`;
const DIRTY_LOGO_BELFRY = `${process.env.PUBLIC_URL}/assets/belfry-dirty.png`;
const DIRTY_LOGO_EDUARTE = `${process.env.PUBLIC_URL}/assets/eduarte-dirty.png`;

interface Props {}

export const About = (props: Props) => {
  const [contentType, setContentType] = useState<string>(ContentType.EDUARTE);
  const [contentData, setContentData] = useState<ContentDataType>();
  const { showLoading, showSnackbar } = useContext(UIContext);

  useEffect(() => {
    showLoading(true);
    sanityClient
      .fetch(`*[_type == "pages" && title == "aboutUs"]`)
      .then((response) => {
        const belfryInfo: string = response[0].belfry_info[0].children[0].text;
        const eduarteInfo: string =
          response[0].eduarte_info[0].children[0].text;
        const radliniokiInfo: string =
          response[0].radlinioki_info[0].children[0].text;
        setContentData({ belfryInfo, eduarteInfo, radliniokiInfo });
        showLoading(false);
      })
      .catch((err: AxiosError) => {
        showLoading(false);
        showSnackbar("error", err.message);
      });
  }, [showLoading, showSnackbar]);

  return (
    <StyledAbout>
      <AboutHeader>O nas</AboutHeader>
      <AboutContent>
        <ContentPicker>
          <ImageWrapper>
            <PickerImage
              onClick={() => setContentType(ContentType.EDUARTE)}
              src={DIRTY_LOGO_EDUARTE}
            />
            <hr />
          </ImageWrapper>
          <ImageWrapper>
            <PickerImage
              onClick={() => setContentType(ContentType.BELFRY)}
              src={DIRTY_LOGO_BELFRY}
            />
            <hr />
          </ImageWrapper>
          <ImageWrapper>
            <PickerImage
              onClick={() => setContentType(ContentType.RADLINIOKI)}
              src={DIRTY_LOGO_RADLINIOKI}
            />
            <hr />
          </ImageWrapper>
        </ContentPicker>
        <ContentText>
          {contentData ? contentData[contentType] : null}
        </ContentText>
      </AboutContent>
    </StyledAbout>
  );
};
