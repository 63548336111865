import styled from "styled-components";

interface SidebarProps {
  opened: boolean;
}

export const StyledSidebar = styled.div<SidebarProps>`
  position: absolute;
  z-index: 1;

  height: ${({ opened }) => (opened ? `auto` : `0`)};
  width: 100%;
  background-color: ${({ opened, theme }) =>
    opened ? theme.color.primary : `transparent`};
  & > :first-child {
    cursor: pointer;
    transform: scale(3);
    margin: 3rem 0 0 3rem;
  }
`;

export const Navigation = styled.div`
  margin: 2rem 4rem;
  font-size: ${({ theme }) => theme.fontSize.md};
  color: ${({ theme }) => theme.color.secondary};
  display: flex;
  flex-direction: column;
  & > span {
    text-align: center;
    position: relative;
    transition: all 0.2s;
    cursor: pointer;
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 40%;
      opacity: 0;
      transform: translateX(-50%);
      width: 2rem;
      height: 3px;
      background-color: ${({ theme }) => theme.color.secondary};
      transition: all 0.2s;
      border-radius: 1rem;
    }
    &:hover {
      transform: translateX(2rem);
      &::before {
        opacity: 1;
        left: -2rem;
      }
    }
  }
`;
