import styled from "styled-components";
import { variables } from "../../core/styles/variables";
const DIALOG_BACKGROUND = `${process.env.PUBLIC_URL}/assets/dialog-background.png`;
const { breakpoints } = variables;

export const BebokDialogWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.7;
  position: absolute;
  z-index: 4;
`;
export const StyledBebokDialog = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: 50rem;
  width: 70rem;
  background-image: url(${DIALOG_BACKGROUND});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding: 2.2rem 6rem 7rem 9rem;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
  @media (max-width: ${breakpoints.sm}) {
    padding: 3rem 14rem 3.5rem 14rem;
    height: 47rem;
    width: 100%;
  }
  @media (max-width: 560px) {
    padding: 3.3rem 10rem 3.5rem 10rem;
    height: 37rem;
    width: 100%;
  }
  @media (max-width: ${breakpoints.xs}) {
    padding: 1.5rem 5.5rem 2rem 8rem;
    height: 27rem;
    width: 100%;
  }
`;
export const BebokName = styled.div`
  font-size: ${({ theme }) => theme.fontSize.head3};
  color: ${({ theme }) => theme.color.primary};
  @media (max-width: ${breakpoints.sm}) {
    font-size: ${({ theme }) => theme.fontSize.xlg};
    margin-bottom: 2rem;
  }
  @media (max-width: ${breakpoints.xs}) {
    font-size: ${({ theme }) => theme.fontSize.md};
    margin-bottom: 2rem;
  }
`;
export const BebokDescription = styled.div`
  font-size: ${({ theme }) => theme.fontSize.md};
  color: ${({ theme }) => theme.color.primary};
  @media (max-width: ${breakpoints.sm}) {
    font-size: ${({ theme }) => theme.fontSize.sm};
    /* margin-bottom: 2rem; */
  }
  @media (max-width: ${breakpoints.xs}) {
    font-size: ${({ theme }) => theme.fontSize.xsm};
  }
`;
