import { AxiosResponse } from "axios";
import Api from "..";
import { BasicResponseData, ExtendedResponseData, LoginRequest, LoginResponse, RegisterRequest } from "./types";

const authUrl = '/auth';

const signUp = (body: RegisterRequest): Promise<AxiosResponse<BasicResponseData>> => {
  return Api.post(`${authUrl}/signup`, body)
}

const signIn = (body: LoginRequest): Promise<AxiosResponse<ExtendedResponseData<LoginResponse>>> => {
  return Api.post(`${authUrl}/signin`, body)
}

export const AuthService = {
  signUp,
  signIn
}