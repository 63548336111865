import { AxiosError } from "axios";
import React, { useContext, useEffect, useState } from "react";
import sanityClient from "../../../api/sanity/sanityClient";
import { Tasks } from "../../../api/sanity/schema";
import UIContext from "../../../contexts/ui/UIContext";
import SingleTask from "./SingleTask/SingleTask";
import { SanityLink, StyledTasksList, TasksHeader } from "./TasksList.style";

type Props = {};

const sanityUrl = "https://gramiejskaradlin.sanity.studio/desk/tasks";

const TasksList = (props: Props) => {
  const { showLoading, showSnackbar } = useContext(UIContext);
  const [tasks, setTasks] = useState<Tasks[]>([]);

  useEffect(() => {
    showLoading(true);
    sanityClient
      .fetch(`*[_type == "tasks"]`)
      .then((response: any) => {
        setTasks(response);
        showLoading(false);
      })
      .catch((err: AxiosError) => {
        showLoading(false);
        showSnackbar("error", err.message);
      });
  }, [showLoading, showSnackbar]);

  return (
    <StyledTasksList>
      <SanityLink
        href={sanityUrl}
        style={{ textDecoration: "none" }}
        target="_blank"
        rel="noreferrer"
      >
        Dodaj zadania
      </SanityLink>
      <TasksHeader>
        <span>Tytuł</span>
        <span>Data utworzenia</span>
        <span>Data modyfikacji</span>
        <span>Opcje</span>
      </TasksHeader>
      {tasks.map((task) => (
        <SingleTask key={task._id} task={task} />
      ))}
    </StyledTasksList>
  );
};

export default TasksList;
