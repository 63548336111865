import React, { ReactNode } from "react";
import { DialogData, DialogType } from "../../contexts/ui/types";
import { Button } from "../button/Button";
import {
  DialogActions,
  DialogBody,
  DialogWrapper,
  StyledDialog,
} from "./Dialog.style";

type Props = DialogData & {
  onDismiss: () => void;
  children: ReactNode;
  btnText: string | ReactNode;
};

export const Dialog = (props: Props) => {
  const { type, children, onSubmit, onDismiss, btnText } = props;

  const onInfoSubmit = () => {
    onSubmit?.();
    onDismiss();
  };

  const renderActions = (type: DialogType) => {
    switch (type) {
      case "info":
        return <Button onClick={onInfoSubmit}>{btnText}</Button>;
      case "warning":
        return (
          <>
            <Button color="primary" onClick={onSubmit ?? (() => null)}>
              Zgoda
            </Button>
            <Button color="tertiary" onClick={onDismiss}>
              Anuluj
            </Button>
          </>
        );
    }
  };

  return (
    <>
      <DialogWrapper />
      <StyledDialog>
        <DialogBody>{children}</DialogBody>
        <DialogActions>{renderActions(type)}</DialogActions>
      </StyledDialog>
    </>
  );
};
