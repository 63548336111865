import { useContext, useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { TasksService } from "../../../api/tasks/tasksService";
import { User, UserType } from "../../../api/types";
import { UsersService } from "../../../api/users/usersService";
import { Button } from "../../../components/button/Button";
import UIContext from "../../../contexts/ui/UIContext";
import {
  DataRow,
  DataWrapper,
  DetailsWrapper,
  DialogWrapper,
  HeadersRow,
  StyledGameStats,
  TasksForUser,
  TitleRow,
} from "./GameStats.styled";

type Props = {};

const GameStats = (props: Props) => {
  const { showLoading, showSnackbar, showDialog } = useContext(UIContext);
  const [users, setUsers] = useState<User[]>([]);

  const handleUserDetails = (userId: string) => {
    showLoading(true);
    TasksService.getTasksForUser(userId)
      .then((response) => {
        const tasks = response.data.data;
        showDialog(
          "info",
          <TasksForUser>
            {tasks.length !== 0
              ? tasks.map((task) => (
                  <DialogWrapper>
                    <span>Zadanie: {task.task_name}</span>
                    <span>Odpowiedź: {task.submitted_answer}</span>
                    <span>
                      Wysłano:{" "}
                      {new Date(task.createdAt).toLocaleString("pl-PL")}
                    </span>
                  </DialogWrapper>
                ))
              : "Brak zadań"}
          </TasksForUser>
        );
        showLoading(false);
        showSnackbar("success", "Pomyślnie załadowano dane");
      })
      .catch(() => {
        showLoading(false);
        showSnackbar("error", "Wystąpił błąd");
      });
  };

  useEffect(() => {
    showLoading(true);
    UsersService.getUsers(UserType.USER)
      .then((response) => {
        setUsers(response.data.data);
        showLoading(false);
        showSnackbar("success", "Dane załadowano pomyślnie");
      })
      .catch((err) => {
        showLoading(false);
        showSnackbar("error", err.message);
      });
  }, [showLoading, showSnackbar]);

  return (
    <StyledGameStats>
      <TitleRow>
        <h3>Użytkownicy</h3>
      </TitleRow>
      <DataWrapper>
        <DetailsWrapper>
          <HeadersRow>
            <span>Email</span>
            <span>Zobacz szczegóły</span>
          </HeadersRow>
          {users.map((user) => (
            <DataRow key={user.id}>
              <span>{user.email}</span>
              <Button
                variant="icon"
                onClick={() => handleUserDetails(user.id)}
                children={<FaEye />}
              />
            </DataRow>
          ))}
        </DetailsWrapper>
      </DataWrapper>
    </StyledGameStats>
  );
};

export default GameStats;
