import styled from "styled-components";

export const DialogWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.7;
  position: absolute;
  z-index: 1;
`;

export const StyledDialog = styled.div`
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  padding: 2rem;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 2rem;
`;
export const DialogNav = styled.div`
  display: flex;
  justify-content: flex-end;
  & > * {
    transform: scale(2);
    cursor: pointer;
    &:hover {
      transform: scale(2.2);
      transition: all 0.1s;
    }
  }
  margin-bottom: 2rem;
`;
export const DialogBody = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.md};
  margin-bottom: 2rem;
`;
export const DialogActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
