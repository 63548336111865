import styled from "styled-components";

export const StyledLogin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const GoToRegister = styled.div`
  margin-top: 2rem;
  & > :first-child {
    font-size: ${({ theme }) => theme.fontSize.sm};
    margin-right: 1rem;
  }
`;
export const FormWrapper = styled.form`
  margin-bottom: 2rem;
`;
