import { AxiosResponse } from "axios";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  BasicResponseData,
  ExtendedResponseData,
  LoginResponse,
} from "../../api/auth/types";
import { ContextType, State } from "./types";
import { getUserIdFromToken, isTokenExpired } from "./utils";
import { UsersService } from "../../api/users/usersService";
import { User, UserType } from "../../api/types";
import UIContext from "../ui/UIContext";

const defaultState: ContextType = {
  handleRegister: () => null,
  handleLogin: () => null,
  handleLogout: () => null,
  registerState: {
    success: false,
    message: "",
  },
  loginState: {
    success: false,
    message: "",
  },
  user: {
    id: "",
    email: "",
    type: UserType.USER,
  },
  loggedIn: false,
};

const AuthContext = createContext<ContextType>(defaultState);
export default AuthContext;

export const AuthProvider = ({ children }: { children?: ReactNode }) => {
  const { showLoading, showSnackbar } = useContext(UIContext);
  const [registerState, setRegisterState] = useState<State>(
    defaultState.registerState
  );
  const [loginState, setLoginState] = useState<State>(defaultState.loginState);
  const [user, setUser] = useState<User>(defaultState.user);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  useEffect(() => {
    const handleToken = () => {
      const localStorageToken = JSON.parse(
        localStorage.getItem("token") as string
      );
      if (localStorageToken) {
        if (isTokenExpired(localStorageToken)) {
          handleLogout();
        } else {
          showLoading(true);
          UsersService.getUser(getUserIdFromToken(localStorageToken))
            .then((response) => {
              setUser(response.data.data);
              setLoggedIn(true);
              showLoading(false);
            })
            .catch((err) => {
              showLoading(false);
              handleLogout();
              showSnackbar("error", "Błąd autoryzacji");
            });
        }
      } else handleLogout();
    };
    handleToken();
  }, [showLoading, showSnackbar]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setUser(defaultState.user);
    setLoginState(defaultState.loginState);
    setRegisterState(defaultState.registerState);
    setLoggedIn(false);
  };
  const handleRegister = (response: AxiosResponse<BasicResponseData>) => {
    setRegisterState(response.data);
  };
  const handleLogin = (
    response: AxiosResponse<ExtendedResponseData<LoginResponse>>
  ) => {
    setLoginState({
      message: response.data.message,
      success: response.data.success,
    });
    localStorage.setItem("token", JSON.stringify(response.data.data.token));
    setUser(response.data.data.user);
    setLoggedIn(true);
  };
  return (
    <AuthContext.Provider
      value={{
        handleLogin,
        handleRegister,
        handleLogout,
        registerState,
        loginState,
        user,
        loggedIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
