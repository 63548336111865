import styled from "styled-components";
import { variables } from "../../../core/styles/variables";
const { breakpoints } = variables;

export const StyledTasksList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 10rem;
  @media (max-width: ${breakpoints.sm}) {
    padding: 1rem;
    margin-top: 5rem;
  }
`;

export const TasksHeader = styled.div`
  display: grid;
  grid-template-columns: 30% 20% 20% 30%;
  padding: 1rem;
  font-size: ${({ theme }) => theme.fontSize.sm};
`;

export const SanityLink = styled.a`
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize.md};
`;
