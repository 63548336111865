import styled from "styled-components";
import { variables } from "../../core/styles/variables";
const { breakpoints } = variables;

export const StyledAbout = styled.div`
  display: flex;
  flex-direction: column;
`;
export const AboutHeader = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.head3};
  text-align: center;
`;
export const AboutContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ContentPicker = styled.div`
  display: flex;
  margin-bottom: 5rem;
`;
export const ImageWrapper = styled.div`
  & > hr {
    width: 2rem;
    height: 3px;
    background-color: ${({ theme }) => theme.color.primary};
    border: none;
  }
`;
export const PickerImage = styled.img`
  width: 10rem;
  height: auto;
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
  &:hover {
    transform: scale(1.05);
  }
`;
export const ContentText = styled.div`
  margin: 0 10%;
  font-size: ${({ theme }) => theme.fontSize.xlg};
  text-align: center;
  line-height: 3.5rem;
  @media (max-width: ${breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.md};
    line-height: 3rem;
  }
  @media (max-width: ${breakpoints.md}) {
    font-size: ${({ theme }) => theme.fontSize.md};
    line-height: 2.5rem;
  }
  @media (max-width: ${breakpoints.sm}) {
    font-size: ${({ theme }) => theme.fontSize.sm};
    line-height: 2.5rem;
  }
`;
