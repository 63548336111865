import styled from "styled-components";

export const StyledRegister = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  & > h1 {
    text-align: center;
    margin-bottom: 4rem;
  }
`;
export const FormWrapper = styled.form`
  margin-bottom: 2rem;
`;

export const AlreadyHaveAccountInfo = styled.p`
  & > :first-child {
    margin-right: 1rem;
    font-size: ${({ theme }) => theme.fontSize.sm};
  }
`;
