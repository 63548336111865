import { useContext, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { UserType } from "../../../api/types";
import { Button } from "../../../components/button/Button";
import AuthContext from "../../../contexts/auth/AuthContext";
import { Navigation, StyledSidebar } from "./Sidebar.style";

interface Props {}

export const Sidebar = (props: Props) => {
  const [opened, setOpened] = useState<boolean>(false);
  const { loggedIn, user, handleLogout } = useContext(AuthContext);
  const history = useHistory();

  const changeSite = (routeCallback: () => void) => {
    setOpened(false);
    routeCallback();
  };

  const renderAdminButton = () => {
    return user.type === UserType.ADMIN ? (
      <Button
        variant="contained"
        color="primary"
        onClick={() => changeSite(() => history.push("/admin"))}
      >
        Panel admina
      </Button>
    ) : null;
  };

  return (
    <StyledSidebar opened={opened}>
      {opened ? (
        <FaTimes onClick={() => setOpened(false)} />
      ) : (
        <FaBars onClick={() => setOpened(true)} />
      )}
      {opened ? (
        <Navigation>
          <span onClick={() => changeSite(() => history.push("/"))}>
            Strona główna
          </span>
          <span onClick={() => changeSite(() => history.push("/oprojekcie"))}>
            O projekcie
          </span>
          <span onClick={() => changeSite(() => history.push("/aktualnosci"))}>
            Aktualności
          </span>
          <span onClick={() => changeSite(() => history.push("/blog"))}>
            Blog
          </span>
          <span onClick={() => changeSite(() => history.push("/kontakt"))}>
            Kontakt
          </span>
          <span onClick={() => changeSite(() => history.push("/onas"))}>
            O nas
          </span>
          {loggedIn ? (
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push("/gra")}
                disabled={false}
              >
                Graj
              </Button>
              {renderAdminButton()}
              <Button
                color="primary"
                variant="contained"
                onClick={handleLogout}
              >
                Wyloguj
              </Button>
            </div>
          ) : (
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => changeSite(() => history.push("/logowanie"))}
              >
                Zaloguj sie
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => changeSite(() => history.push("/rejestracja"))}
              >
                Zarejestruj sie
              </Button>
            </div>
          )}
        </Navigation>
      ) : null}
    </StyledSidebar>
  );
};
