import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";
import { BebokDialog } from "../../components/bebok-dialog/BebokDialog";
import { Dialog } from "../../components/dialog/Dialog";
import { Loading } from "../../components/loading/Loading";
import { Snackbar } from "../../components/snackbar/Snackbar";
import GlobalStyles from "../../core/styles/globalStyles";
import { theme } from "../../core/styles/theme";
import { DialogType, SnackbarData, SnackbarType, UIContextType } from "./types";

const defaultState = {
  showSnackbar: () => null,
  showLoading: () => null,
  showDialog: () => null,
  closeDialog: () => null,
  showBebok: () => null,
};

const UIContext = createContext<UIContextType>(defaultState);
export default UIContext;

const AUTO_DISMISS = 5000;

export const UIProvider = ({ children }: { children?: ReactNode }) => {
  const [snackbarData, setSnackbarData] = useState<SnackbarData>({
    type: "info",
    message: "",
  });
  const [snackbarVisibility, setSnackbarVisibility] = useState<boolean>(false);
  const [loadingVisibility, setLoadingVisibility] = useState<boolean>(false);
  const [dialogVisiblity, setDialogVisibility] = useState<boolean>(false);
  const [bebokVisibility, setBebokVisibility] = useState<boolean>(false);

  useEffect(() => {
    if (!dialogVisiblity && !bebokVisibility) {
      document.getElementById("dialog")?.setAttribute("style", "display: none");
    } else {
      document.getElementById("dialog")?.removeAttribute("style");
    }
  }, [dialogVisiblity, bebokVisibility]);

  const showSnackbar = useCallback((type: SnackbarType, message: string) => {
    setSnackbarData({ type: type, message: message });
    setSnackbarVisibility(true);
  }, []);

  const showLoading = useCallback((isLoading: boolean) => {
    setLoadingVisibility(isLoading);
  }, []);

  const showDialog = (
    type: DialogType,
    children: ReactNode,
    onSubmit?: () => void,
    btnText?: string | ReactNode
  ) => {
    setDialogVisibility(true);
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Dialog
          type={type}
          onSubmit={onSubmit ?? (() => null)}
          onDismiss={closeDialog}
          children={children}
          btnText={btnText ?? "OK"}
        />
      </ThemeProvider>,
      document.getElementById("dialog")
    );
  };

  const closeDialog = () => {
    setDialogVisibility(false);
  };

  const closeBebok = () => {
    setBebokVisibility(false);
  };

  const showBebok = (name: string, description: string) => {
    setBebokVisibility(true);
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <BebokDialog
          name={name}
          description={description}
          onDismiss={closeBebok}
        />
      </ThemeProvider>,
      document.getElementById("dialog")
    );
  };

  useEffect(() => {
    if (snackbarVisibility) {
      const timer = setTimeout(
        () => setSnackbarVisibility(false),
        AUTO_DISMISS
      );
      return () => clearTimeout(timer);
    }
  }, [snackbarVisibility]);

  return (
    <UIContext.Provider
      value={{ showSnackbar, showLoading, showDialog, closeDialog, showBebok }}
    >
      {children}
      {snackbarVisibility ? (
        <Snackbar type={snackbarData.type} message={snackbarData.message} />
      ) : null}
      {loadingVisibility ? <Loading /> : null}
    </UIContext.Provider>
  );
};
