import { JwtDecoded } from './types';
const jwt = require('jsonwebtoken');

export const isTokenExpired = (token: string): boolean => {
  const decoded: JwtDecoded = jwt.decode(token, process.env.REACT_APP_JWT_KEY as string)
  const timeInMs = Math.round(new Date().getTime()/1000);
  return timeInMs > decoded.exp ? true : false
}

export const getUserIdFromToken = (token: string): string => {
  const decoded: JwtDecoded = jwt.verify(token, process.env.REACT_APP_JWT_KEY as string);
  return decoded.userId;
}