import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const activeLink = {
  backgroundColor: "#a8b9b3",
};

export const StyledAdminPage = styled.div`
  display: flex;
  height: 100%;
`;
export const AdminNav = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.tertiary};
`;
export const AdminNavLink = styled(NavLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.color.black};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSize.md};
  padding: 3rem 2rem;
  transition: all 0.3s;
  &:hover {
    cursor: pointer;
    background-color: #a8b9b3;
  }
  & > :first-child {
    margin-bottom: 2rem;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  overflow-y: auto;
  overflow-x: visible;
  background-color: ${({ theme }) => theme.color.white};
`;
