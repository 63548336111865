import { useContext, useEffect } from "react";
import { FaRegUser, FaTasks } from "react-icons/fa";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import AuthContext from "../../contexts/auth/AuthContext";
import {
  activeLink,
  AdminNav,
  AdminNavLink,
  ContentWrapper,
  StyledAdminPage,
} from "./AdminPage.style";
import TasksList from "./Tasks/TasksList";
import GameStats from "./Users/GameStats";

interface Props {}

export const AdminPage = (props: Props) => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { loggedIn, user } = useContext(AuthContext);

  useEffect(() => {
    history.push(`${path}/quests`);
  }, [history, path]);

  return (
    <>
      {loggedIn && user.type === "ADMIN" ? (
        <StyledAdminPage>
          <AdminNav>
            <AdminNavLink to={`${path}/users`} activeStyle={activeLink}>
              <FaRegUser />
              <span>Użytkownicy</span>
            </AdminNavLink>
            <AdminNavLink to={`${path}/quests`} activeStyle={activeLink}>
              <FaTasks />
              <span>Zadania</span>
            </AdminNavLink>
          </AdminNav>
          <Switch>
            <ContentWrapper>
              <Route path={`${path}/users`}>
                <GameStats />
              </Route>
              <Route path={`${path}/quests`}>
                <TasksList />
              </Route>
            </ContentWrapper>
          </Switch>
        </StyledAdminPage>
      ) : (
        history.push("/")
      )}
    </>
  );
};
