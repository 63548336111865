import PortableText from "react-portable-text";
import styled from "styled-components";

export const StyledAnnounementPage = styled.div`
  margin: 3rem 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > :first-child {
    margin-bottom: 2rem;
  }
`;
export const StyledPortableText = styled(PortableText)`
  figure {
    margin: 0;
    text-align: center;
    & > img {
      max-width: 100%;
    }
  }
  & > * {
    font-size: ${({ theme }) => theme.fontSize.md};
  }
`;
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.xlg};
  border-bottom: 3px solid ${({ theme }) => theme.color.secondary};
  padding: 1rem 2rem;
`;
