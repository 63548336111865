import {
  Document,
  Font,
  Image,
  Page,
  Path,
  pdf,
  Svg,
  Text,
  View,
} from "@react-pdf/renderer";
import { useContext, useRef } from "react";
import { FaQrcode, FaRegEye } from "react-icons/fa";
import QRCode from "react-qr-code";
import { Tasks } from "../../../../api/sanity/schema";
import { Button } from "../../../../components/button/Button";
import UIContext from "../../../../contexts/ui/UIContext";
import { ActionsWrapper, StyledSingleTask } from "./SingleTask.style";
import { saveAs } from "file-saver";
import AuthContext from "../../../../contexts/auth/AuthContext";
import { UserType } from "../../../../api/types";
import { useHistory } from "react-router-dom";
import { Task } from "../../../../api/tasks/types";

interface Props {
  task: Tasks | Task;
}

interface QRDocumentProps {
  qrCodeRef: HTMLDivElement | null;
  title: string;
}

const MAP_URL = `${process.env.PUBLIC_URL}/assets/mapa.png`;
const TITLE_URL = `${process.env.PUBLIC_URL}/assets/title.png`;
const LOGO_KOMITET = `${process.env.PUBLIC_URL}/assets/logo-komitet.png`;
const LOGO_NIW = `${process.env.PUBLIC_URL}/assets/logo-niw.png`;
const LOGO_NOWEFIO = `${process.env.PUBLIC_URL}/assets/logo-nowefio.png`;
const UCHIYAMAFONT_URL = `${process.env.PUBLIC_URL}/fonts/uchiyama.ttf`;
const ARIAL_URL = `${process.env.PUBLIC_URL}/fonts/ARIAL.ttf`;

const QRDocument = ({ qrCodeRef, title }: QRDocumentProps) => {
  Font.register({ family: "Uchiyama", src: UCHIYAMAFONT_URL });
  Font.register({
    family: "Arial",
    src: ARIAL_URL,
  });
  const pathElements =
    qrCodeRef && (Array.from(qrCodeRef?.children[0].children) as any[]);

  return (
    <Document>
      <Page
        size={"A4"}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          border: "1px solid red",
          backgroundColor: "#F4B23C",
        }}
      >
        <View
          style={{
            flex: "1",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <Image source={TITLE_URL} />
          <Image source={MAP_URL} style={{ height: "30%", width: "auto" }} />
          <Text
            style={{
              marginBottom: "30px",
              fontSize: "20px",
              fontFamily: "Uchiyama",
            }}
          >
            {title}
          </Text>
          <Svg
            style={{
              width: "43%",
            }}
          >
            {pathElements?.map(
              ({ attributes: { d, fill, transform } }, index) => (
                <Path
                  key={index}
                  d={d.value}
                  fill={fill.value}
                  transform={transform.value}
                />
              )
            )}
          </Svg>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            width: "100%",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              height: "80px",
            }}
          >
            <Image
              source={LOGO_KOMITET}
              style={{ width: "30%", height: "auto" }}
            />
            <Image
              source={LOGO_NIW}
              style={{ width: "23%", height: "auto", marginBottom: "10px" }}
            />
            <Image
              source={LOGO_NOWEFIO}
              style={{ width: "30%", height: "auto" }}
            />
          </View>
          <Text
            style={{
              fontSize: "10px",
              fontFamily: "Arial",
              color: "black",
              paddingBottom: "10px",
              paddingHorizontal: "5px",
            }}
          >
            Sfinansowano ze środków Narodowego Instytutu Wolności - Centrum
            Rozwoju Społeczeństwa Obywatelskiego w ramach Rządowego Programu
            Fundusz Inicjatyw Obywatelskich NOWE FIO na lata 2021-2030
          </Text>
        </View>
      </Page>
    </Document>
  );
};

const SingleTask = ({ task }: Props) => {
  const { title, _createdAt, _updatedAt, _id } = task as Tasks;
  const { REACT_APP_DOMAIN: url } = process.env;
  const { showDialog } = useContext(UIContext);
  const history = useHistory();
  const {
    user: { type },
  } = useContext(AuthContext);
  const qrCodeRef = useRef(null);

  const downloadPdf = async () => {
    const doc = (
      <QRDocument qrCodeRef={qrCodeRef.current} title={title as string} />
    );
    const asPDF = pdf([] as any);
    asPDF.updateContainer(doc);
    const blob = await asPDF.toBlob();
    saveAs(blob, `${title}-QR.pdf`);
  };

  const handlePreview = () => history.push(`/zadania/${_id}`);

  return (
    <StyledSingleTask>
      <span>{title}</span>
      <span>{new Date(_createdAt).toLocaleString("pl-PL")}</span>
      <span>{new Date(_updatedAt).toLocaleString("pl-PL")}</span>
      <ActionsWrapper>
        <Button onClick={handlePreview} variant="icon">
          <FaRegEye />
        </Button>
        {type === UserType.ADMIN && (
          <Button
            onClick={() =>
              showDialog(
                "warning",
                <div>
                  Pobierz kod QR do PDF
                  <div ref={qrCodeRef}>
                    <QRCode value={`${url}/zadania/${_id}`} />
                  </div>
                </div>,
                downloadPdf
              )
            }
            variant="icon"
          >
            <FaQrcode />
          </Button>
        )}
      </ActionsWrapper>
    </StyledSingleTask>
  );
};

export default SingleTask;
